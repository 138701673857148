import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import ItemGrid from "../shared/ItemGrid";
import { FolderOpenIcon, TrashIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { useConfigurator, useProject } from "../../hooks/useQueries";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-modal-global";
import PopupModal from "../shared/Modal";

export default function ProjectItemsGrid({ items }) {
  const { selectVentilationUnit } = useConfigurator();
  const { deleteProjectItem, cloneProjectItem } = useProject();

  const navigate = useNavigate();

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("modelName", {
      // cell: (info) => info.getValue(),
      header: "Model",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("reference", {
      id: "createdDate",
      // cell: (info) => <i>{info.getValue()}</i>,
      header: "Reference",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("volume", {
      // id: "volume",
      cell: (info) => info.getValue() + ` (${info.row.original.volumeUnit})`,
      header: (props) => "Volume",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("externalStaticPressure", {
      // id: "volume",
      cell: (info) => info.getValue() + ` (${info.row.original.pressureUnit})`,
      header: (props) => "External Static Pressure",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("filterVelocity", {
      header: "Filter Velocity",
      cell: (info) => info.renderValue() + " m/s",
      //footer: (info) => info.column.id,
    }),
    // columnHelper.accessor("eSPFaceVelocity", {
    //   header: "ESP Velocity (m/s)",
    //   // cell: (info) => info.renderValue(),
    //   //footer: (info) => info.column.id,
    // }),
    columnHelper.accessor("price", {
      header: "Price (AED)",
      // cell: (info) => info.renderValue(),
      //footer: (info) => info.column.id,ss
    }),
    columnHelper.accessor("quantity", {
      header: "Quantity",
      // cell: (info) => info.renderValue(),
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("totalPrice", {
      header: "Total Price (AED)",
      // cell: (info) => info.renderValue(),
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("", {
      id: "cloneItem",
      cell: (props) => <DocumentDuplicateIcon className="h-5 w-5 text-green-600 cursor-pointer" onClick={() => onCloneProjectItem(props?.row?.original?.guid)} />,
    }),
    columnHelper.accessor("", {
      id: "openUnit",
      cell: (props) => <FolderOpenIcon className="h-5 w-5 text-green-600 cursor-pointer" onClick={() => onOpenProjectItem(props?.row?.original?.guid)} />,
    }),
    columnHelper.accessor("", {
      id: "deleteUnit",
      cell: (props) => <TrashIcon className="h-5 w-5 text-red-600 cursor-pointer" onClick={() => onDeleteProjectItem(props?.row?.original?.guid)} />,
    }),
  ];

  function onCloneProjectItem(id)
  {
    cloneProjectItem({resultId:id});

  }

  function onOpenProjectItem(id) {
    selectVentilationUnit({ ventilationUnitId: id, referrer: "Project" });
    navigate("/selection");
  }

  function onDeleteProjectItem(id) {
    Modal.open(PopupModal, {
      title: "",
      weak: true,
      body: ["Are you sure you want to delete this unit?"],
      callback: () => {
        deleteProjectItem({ resultId: id })
      },
      closable: false,
    });    
  }

  return (
    <>
      <ItemGrid columns={columns} data={items}></ItemGrid>
      {/* <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Example Modal">
        <ProjectLists projectLists={projectLists} onOpenProject={handleOpenProject} onDeleteProject={handleonDeleteProject}></ProjectLists>
      </Modal> */}
    </>
  )
}
