import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import ItemGrid from "../shared/ItemGrid";
import { FolderOpenIcon, TrashIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useAdmin } from "../../hooks/useQueries";
import { useNavigate } from "react-router-dom";
import Button from "../shared/Button";
import { Modal } from "react-modal-global";
import PopupModal from "../shared/Modal";

export default function UserGrid() {
  const { userList, approveUser, deleteUser, revokeUser } = useAdmin();

  const navigate = useNavigate();

  function onApproveHandler(id, email) {
    Modal.open(PopupModal, {
      title: "APPROVE",
      weak: true,
      body: [`Are you sure you want to approve the user registration for ${email}?`],
      callback: () => {
        approveUser({ id: id });
      },
      closable: false,
    });
  }
  function onRevokeHandler(id, email) {
    Modal.open(PopupModal, {
      title: "REVOKE",
      weak: true,
      body: [`Are you sure you want to revoke the user account for ${email}?`],
      callback: () => {
        revokeUser({ id: id });
      },
      closable: false,
    });
  }
  function onDeleteHandler(id, email) {
    Modal.open(PopupModal, {
      title: "DELETE",
      weak: true,
      body: [`Are you sure you want to delete the user account for ${email}?`],
      callback: () => {
        deleteUser({ id: id });
      },
      closable: false,
    });
  }

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("firstName", {
        id:"firstName",
      // cell: (info) => info.getValue(),
      header: "First Name",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("lastName", {
      id: "LastName",
      // cell: (info) => <i>{info.getValue()}</i>,
      header: "Reference",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("", {
      id: "verified",
      cell: (props) =>
        props?.row?.original?.isVerified ? (
          <CheckCircleIcon className="h-5 w-5 m-auto text-green-400" aria-hidden="true" />
        ) : (
          <XCircleIcon className="h-5 w-5 m-auto text-red-400" aria-hidden="true" />
        ),
      header: <div className="text-center">Verified</div>,
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("email", {
       id: "email",
      // cell: (info) => <i>{info.getValue()}</i>,
      header: "Email",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("", {
    id: "approve",
      cell: (props) =>
        props?.row?.original?.approved ? (
          <Button
            text="Revoke"
            buttonSize="xs"
            buttoncolor={"danger"}
            cssclass="!rounded-md mr-3"
            onClick={() => onRevokeHandler(props?.row?.original?.id, props?.row?.original?.email)}
          />
        ) : (
          <Button
            text="Approve"
            buttonSize="xs"
            buttoncolor={"primary"}
            cssclass="!rounded-md mr-3"
            onClick={() => onApproveHandler(props?.row?.original?.id, props?.row?.original?.email)}
          />
        ),
      //header: "Approve",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("", {
       id: "deleteUser",
      cell: (props) => <TrashIcon className="h-5 w-5 text-red-600 cursor-pointer" onClick={() => onDeleteHandler(props?.row?.original?.id, props?.row?.original?.email)} />,
      header: "Delete",
      //footer: (info) => info.column.id,
    }),
  ];


  return <ItemGrid columns={columns} data={userList}></ItemGrid>;
}
