import { RouterProvider } from "react-router-dom";
import { Router } from "./AppRoutes";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "./react-query/queryClient";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import { ModalContainer } from "react-modal-global";

export default function App(props) {
  const displayName = App.name;
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={Router}></RouterProvider>
      <ToastContainer hideProgressBar position="bottom-right"></ToastContainer>
      <ModalContainer />
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
