export const selectionQueryKeys = {
  selectProducts: "selectProducts",
  getFields: "getFields",
  selectionInformation: "selectionInformation",
  getVentilationInformation: "getVentilationInformation",
};

export const configuratorQueryKeys = {
  getConfigurationInstances: "getConfigurationInstances",
  GetConfigurationInstance:"GetConfigurationInstance",
  getConfigurationItems: "getConfigurationItems",
  GetConfigurationInstanceBOMId: "GetConfigurationInstanceBOMId",
  GetSelectedUnitModelName: "GetSelectedUnitModelName",
  selectVentilationUnit:"selectVentilationUnit",
  getSelectedUnitFans:"getSelectedUnitFans",
  resetSelection:"resetSelection",
  getReferrer:"getReferrer",
};

export const designerQueryKeys = {
  getAHUDesignerManaager :"getAHUDesignerManaager"
}

export const modalQueryKeys = {
  modal:"modal"
}

export const printQueryKeys = {
  print:"print"
}

export const technicalQueryKeys = {
  getTechnicalSections:"getTechnicalSections"
}
export const chartQueryKeys = {
  getChartModel:"getChartModel"
}
export const userQueryKeys = {
  user:"user"
}

export const projectQueryKeys = {
  getCurrentProject:"getCurrentProject",
  getProjectItems:"getProjectItems",
  getProjectLists:"getProjectLists",
  getProjectLocations:"getProjectLocations",
  getProjectCities:"getProjectCities",
  getProjectStatus:"getProjectStatus",
  getPOStatus:"getPOStatus",
  getReasonForLost:"getReasonForLost",
  getCompetitor:"getCompetitor",
  getConsultant:"getConsultant",
  getServiceType:"getServiceType",
  cloneProjectItem:"cloneProjectItem",

}
export const pricingQueryKeys = {
  getPricingList:"getPricingList",
}
export const adminQueryKeys = {
  getUserList:"getUserList",
}

