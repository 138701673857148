import { useState, useMemo, useEffect } from "react";
import Input from "../components/shared/Input";
import ItemGrid from "../components/selection/ItemGrid";
import { useForm, useFormState, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useProject, useUser } from "../hooks/useQueries";
import Tabs from "../components/designer/Tabs";
import Button from "../components/shared/Button";
import ProjectHeader from "../components/project/ProjectHeader";
import ProjectLists from "../components/project/ProjectLists";
import Modal from "react-modal";
import ProjectItemsGrid from "../components/project/ProjectItemsGrid";
import { toast } from "react-toastify";

const Project = () => {
  const { user } = useUser();
  const [projectLocation, setProjectLocation] = useState();
  const {
    project,
    setProject,
    projectItems,
    saveProject,
    projectLists,
    openProject,
    newProject,
    selectProjectItem,
    deleteProject,
    projectLocations,
    cities,
    projectStatus,
    competitors,
    poStatus,
    lostReasons,
    serviceTypes,
    consultants,
  } = useProject(projectLocation);
  const [modalIsOpen, setIsOpen] = useState(false);

  const handleProjectLocationChange = (locationCode) => {
    setProjectLocation(locationCode);
  };

  const handleOpenProject = (projectId, revision) => {
    openProject({ projectId: projectId, userId: user.id, Revision: revision });
    closeModal();
  };

  const handleNewProject = (projectId) => {
    newProject();
    closeModal();
  };

  // const handleonDeleteProject = (projectId) => {
  //   Modal.open(PopupModal, {
  //     title: "",
  //     weak: true,
  //     body: ["Are you sure you want to delete this project?"],
  //     callback: () => {
  //       deleteProject({ projectId: projectId, userId: user.id, Revision: 0 })
  //     },
  //     closable: false,
  //   }); 
    
  //   //closeModal();
  // };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    contact: yup.string().required("Required"),
    projectLocation: yup.string().required("Required"),
    projectStatus: yup.string().required("Required"),
    consultant: yup.string().required("Required"),
  });

  // const validationSchema = yup.object().shape({
  //   ...project,
  // });

  // const {
  //   reset,
  //   register,
  //   formState: { errors },
  //   getValues,
  // } = useForm({
  //   resolver: yupResolver(validationSchema),
  //   defaultValues: useMemo(() => {
  //     return project;
  //   }, [project]),
  // });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    getFieldState,
    getValues,
    trigger,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(() => {
      return project;
    }, [project]),
  });

  const onError = (errors, e) => console.log(errors, e);


  useEffect(() => {
    reset(project);
  }, [project]);

  const pOStatus = watch("poStatus");
  console.log(watch("poStatus"));

  const columns = [
    {
      name: "Model",
      selector: (row) => row.ventilationUnitId,
      sortable: true,
    },
    {
      name: "Filter F/Velocity (m/s)",
      selector: (row) => row.velocity.toFixed(2),
      sortable: true,
    },
    {
      name: "ESP F/Velocity (m/s)",
      selector: (row) => row.espFaceVelocity.toFixed(2),
      sortable: true,
    },
    {
      name: "Area",
      selector: (row) => row.usableArea.toFixed(2),
      sortable: true,
    },
    {
      name: "Fan Model",
      selector: (row) => row.fanModel,
      sortable: true,
    },
    {
      name: "Fan Style",
      selector: (row) => row.fanStyle,
      sortable: true,
    },
    // {
    //   name: "Fan Class",
    //   selector: (row) => row.fanClass,
    //   sortable: true,
    // },
    {
      name: "Volume (m/s)",
      selector: (row) => row.selectionVolume,
      sortable: true,
    },
    {
      name: "Pressure (Pa)",
      selector: (row) => row.selectionPressure.toFixed(2),
      sortable: true,
    },
    {
      name: "Efficiency (%)",
      selector: (row) => row.selectionStaticEfficiency.toFixed(2),
      sortable: true,
    },
    // {
    //   selector: (row) =>
    //     <Button buttonSize="xs" text="select" buttoncolor="primary" onClick={() => selectProjectItem(row.guid)}></Button>,
    //   compact: true,
    // },
  ];

  const [leftTabs, setLeftTabs] = useState([{ id: "ProjectDetails", name: "Project Details", href: "", current: true, icon: null }]);

  const saveProjectHandler = async () => {
    const result = await trigger();
    if (result) {
      saveProject({ project: getValues(), IncrementRevision: false, userId: user?.id });
    } else {
      console.log(errors);
      toast("Please fill in required fields.");
    }
  };

  const openProjectListModal = () => {
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);

  }

  return (
    <form >
      <div className="grid md:grid-cols-4 sm:grid-cols-1 gap-2">
        <div className="col-span-1">
          <div className="overflow-hidden rounded-lg bg-white shadow mb-3">
            <div className="rounded-lg bg-white col-span-1">
              <Tabs tabs={leftTabs}></Tabs>
            </div>

            <div className="pt-0 overflow-hidden transition-[max-height] duration-200 ease-in">
              <div className="py-1 sm:px-3">
                <Input
                  size="sm"
                  elementtype="input"
                  label="Quotation"
                  name="quotation"
                  id="quotation"
                  disabled
                  type="text"
                  {...register("quotationNumber", { required: true })}
                  error={errors.title}
                />
                {errors.title && <span className="text-red-500 text-xs">{errors.title.message}</span>}
              </div>
              <div className="py-1 sm:px-3">
                <Input elementtype="input" label="Project Name (required)" name="projectName" id="projectName" type="text" {...register("name", { required: true })} error={errors.name} />
                {errors.name && <span className="text-red-500 text-xs">{errors.name.message}</span>}
              </div>
              <div className="py-1 sm:px-3">
                <Input elementtype="input" label="Reference" name="reference" id="reference" type="text" {...register("reference")} />
              </div>
              {/* <div className="py-1 sm:px-3">
                <Input elementtype="input" label="MEP Contractor" name="mepContractor" id="mepContractor" type="text" {...register("mepContractor")} />
              </div> */}
              <div className="py-1 sm:px-3">
                <Input elementtype="input" label="Email" name="email" id="client" type="text" {...register("email")} />
              </div>
              <div className="py-1 sm:px-3">
                <Input elementtype="input" label="Contact (required)" name="contact" id="contact" type="text" {...register("contact", { required: true })} error={errors.contact} />
                {errors.contact && <span className="text-red-500 text-xs">{errors.contact.message}</span>}
              </div>
              <div className="py-1 sm:px-3">
                <Input disabled elementtype="input" label="Project Date" name="projectDate" id="consultant" type="text" {...register("projectDate")} />
              </div>
              <div className="py-1 sm:px-3">
                <Input
                  options={projectLocations.map(function (pi) {
                    return { value: pi.locationCode, data: pi.location };
                  })}
                  elementtype="select"
                  addBlankOption={true}
                  label="Project Location (required)"
                  name="projectLocation"
                  id="projectLocation"
                  type="text"
                  {...register(
                    "projectLocation",
                    {
                      onChange: (e) => handleProjectLocationChange(e.target.value),
                    },
                    { required: true }
                  )}
                  error={errors.projectLocation}
                />
                {errors.projectLocation && <span className="text-red-500 text-xs">{errors.projectLocation.message}</span>}
              </div>
              <div className="py-1 sm:px-3">
                <Input
                  options={cities.map(function (pi) {
                    return { value: pi.city1, data: pi.city1 };
                  })}
                  elementtype="select"
                  addBlankOption={true}
                  label="City"
                  name="city"
                  id="city"
                  type="text"
                  {...register("city")}
                />
              </div>
              <div className="py-1 sm:px-3">
                <Input
                  options={projectStatus.map(function (pi) {
                    return { value: pi.id, data: pi.description };
                  })}
                  elementtype="select"
                  addBlankOption={true}
                  label="Project Status (required)"
                  name="projectStatus"
                  id="projectStatus"
                  type="text"
                  {...register("projectStatus", { required: true })}
                  error={errors.projectStatus}
                />
                {errors.projectStatus && <span className="text-red-500 text-xs">{errors.projectStatus.message}</span>}
              </div>
              <div className="py-1 sm:px-3">
                <Input
                  options={poStatus.map(function (pi) {
                    return { value: pi.id, data: pi.description };
                  })}
                  elementtype="select"
                  addBlankOption={true}
                  label="PO Status"
                  name="poStatus"
                  id="poStatus"
                  type="text"
                  {...register("poStatus")}
                />
              </div>
              {pOStatus == "LOST" && (
                <div className="py-1 sm:px-3">
                  <Input
                    options={lostReasons.map(function (pi) {
                      return { value: pi.id, data: pi.description };
                    })}
                    elementtype="select"
                    addBlankOption={true}
                    label="Reason For Lost"
                    name="reasonForLost"
                    id="reasonForLost"
                    type="text"
                    {...register("reasonForLost")}
                  />
                </div>
              )}

              <div className="py-1 sm:px-3">
                <Input
                  options={competitors.map(function (pi) {
                    return { value: pi.id, data: pi.description };
                  })}
                  elementtype="select"
                  addBlankOption={true}
                  label="Competitor"
                  name="competitor"
                  id="competitor"
                  type="text"
                  {...register("competitor")}
                />
              </div>
              <div className="py-1 sm:px-3">
                <Input elementtype="input" label="Client" name="client" id="client" type="text" {...register("client")} />
              </div>
              <div className="py-1 sm:px-3">
                <Input
                  options={consultants.map(function (pi) {
                    return { value: pi.consultant1, data: pi.consultant1 }
                  })}
                  elementtype="select"
                  addBlankOption={true}
                  label="Consultant (required)"
                  name="consultant"
                  id="consultant"
                  type="text"
                  {...register("consultant", { required: true })}
                  error={errors.consultant}
                />
                {errors.consultant && <span className="text-red-500 text-xs">{errors.consultant.message}</span>}
              </div>
              <div className="py-1 sm:px-3">
                <Input elementtype="input" label="Main Contractor" name="mainContractor" id="mainContractor" type="text" {...register("mainContractor")} />
              </div>
              {/* <div className="py-1 sm:px-3">
                <Input
                  options={serviceTypes.map(function (pi) {
                    return { value: pi.id, data: pi.description }
                  })}
                  elementtype="select"
                  addBlankOption={true}
                  label="Service Type"
                  name="serviceType"
                  id="serviceType"
                  type="text"
                  {...register("serviceType")}
                />
              </div>
              <div className="py-1 sm:px-3">
                <Input elementtype="input" label="Notes" name="notes" id="notes" type="text" {...register("notes")} />
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-span-3 overflow-hidden rounded-lg bg-white shadow text-right">
          <ProjectHeader saveProject={saveProjectHandler} getProjects={openProjectListModal} onNewProject={handleNewProject}></ProjectHeader>

          <ProjectItemsGrid items={projectItems}></ProjectItemsGrid>

          {/* <ItemGrid
          columns={columns}
          data={projectItems}
          // conditionalRowStyles={conditionalRowStyles}
          // progressPending={resultsLoading}
          // selectableRows
          // onSelectedRowsChange={selectedRowChangedHandler}
          pagination
        /> */}
        </div>

        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Example Modal">
          <ProjectLists projectLists={projectLists} onOpenProject={handleOpenProject} user={user}></ProjectLists>
        </Modal>
      </div>
    </form>
  );
};

export default Project;
