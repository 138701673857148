import create from "zustand";
import { subscribeWithSelector, shallow } from "zustand/middleware";

export const userStore = create(
  subscribeWithSelector((set) => ({
    user: JSON.parse(localStorage.getItem("user")),
    setUser: (user) => set(() => ({ user: user })),
    isAuthenticated:  localStorage.getItem("user") != null,
    setIsAuthenticated: (isAuthenticated) => set(() => ({ isAuthenticated: isAuthenticated })),
  }))
);

export const projectStore = create(
  subscribeWithSelector((set) => ({
    project: null,
    setProject: (project) => set(() => ({ project: project }))
  }))
);

// export const selectionStore = create(
//   subscribeWithSelector((set) => ({
//     referrer: null,
//     setReferrer: (referrer) => set(() => ({ referrer: referrer}))
//   }))
// );
