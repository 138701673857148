export default function Button(props) {
  const buttonPaddingX = () => {
    if (props.buttonSize) {
      if (props.buttonSize == "xs") return "px-2.5";
      if (props.buttonSize == "sm") return "px-3";
      if (props.buttonSize == "md") return "px-4";
      if (props.buttonSize == "lg") return "px-4";
      if (props.buttonSize == "xl") return "px-6";
    } else {
      return "px-4";
    }
  };

  const buttonPaddingY = () => {
    if (props.buttonSize) {
      if (props.buttonSize == "xs") return "py-1.5";
      if (props.buttonSize == "sm") return "py-2";
      if (props.buttonSize == "md") return "py-2";
      if (props.buttonSize == "lg") return "py-2";
      if (props.buttonSize == "xl") return "py-3";
    } else {
      return "py-2";
    }
  };

  const buttonTextSize = () => {
    if (props.buttonSize) {
      if (props.buttonSize == "xs") return "text-xs";
      if (props.buttonSize == "sm") return "text-sm";
      if (props.buttonSize == "md") return "text-sm";
      if (props.buttonSize == "lg") return "text-base";
      if (props.buttonSize == "xl") return "text-base";
    } else {
      return "text-sm";
    }
  };

  const color = () => {
    if (props.buttoncolor) {
      if (props.buttoncolor == "primary")
      {
        return "bg-primary hover:bg-primary-600 focus:ring-primary-500";
      }
      else if (props.buttoncolor == "success")
      {
        return `bg-green-500 hover:bg-green-600 focus:ring-green-500`;
      }
      else if (props.buttoncolor == "danger")
      {
        return `bg-red-500 hover:bg-red-600 focus:ring-red-500`;
      }
      else if (props.buttoncolor == "disabled"){
        return `bg-gray-300 hover:bg-gray-600 focus:bg-gray-500 text-black`;

      }
    }     
    else {
      return "bg-primary hover:bg-primary-600 focus:ring-primary-500";
    }
  };

  const buttonClass = `${props.cssclass} inline-flex items-center rounded border border-transparent ${color()} ${buttonPaddingX()} ${buttonPaddingY()} ${buttonTextSize()} font-medium text-white shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2`;

  return (
    <button type="button" className={buttonClass} {...props}>
      {props.icon && (
        <props.icon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
      )}
      {props.text}
      {props.children}
    </button>
  );
}
