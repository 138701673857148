import { useState, useEffect } from "react";
import { useStore } from "../designer/Store/zustandStore";
import Tabs from "../designer/Tabs";
import Button from "../shared/Button";

export default function Drawings() {
  const [setRequestDrawings, drawings, drawingTolerance, ,requestDrawings] = useStore((state) => [
    state.setRequestDrawings,
    state.drawings,
    state.drawingTolerance,
    state.setDrawingTolerance,
    state.requestDrawings,
  ]);

  return (
    <div className="p-5">
      {/* <Button onClick={() => setRequestDrawings(true)}>Refresh drawings</Button> */}

      {drawings?.length > 0 && (
        <div>
          <h4>Side view</h4>
          <hr></hr>
          <br></br>
          <img src={drawings[0].side} />
          <h4>Top view</h4>
          <hr></hr>
          <br></br>
          <img src={drawings[0].top} />
        </div>
      )}
    </div>
  );
}
