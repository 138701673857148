import * as THREE from "three";
import { useMemo } from "react";
import { Edges } from "@react-three/drei";
import { COMPONENT_ALIGNMENT } from "../Constants";
import FlangeProperties from "./Properties/FlangeProperties";
import { align, fromMM } from "../Utils/frameUtils";
import { buildCurvedDuct, buildDuct, buildDuctPlateWithHole } from "../Utils/geometryUtils";
import { useStore } from "../Store/zustandStore";
import FusibleLinks from "./FusibleLink";

export default function Flange({ frame, getColour, flangeWidth, flangeHeight, flangeLength, flangeDistanceFromTop }) {
  const { flange } = frame;
  return flange?.typeOfFlange == "FUSIBLE_LINK" ? (
    <FusibleLinks frame={frame} />
  ) : (
    <FlangeBasic
      frame={frame}
      getColour={getColour}
      flangeWidth={flangeWidth}
      flangeHeight={flangeHeight}
      flangeLength={flangeLength}
      flangeDistanceFromTop={flangeDistanceFromTop}
    />
  );
}

export function FlangeBasic({ frame, getColour, flangeWidth, flangeHeight, flangeLength, flangeDistanceFromTop }) {
  const [frameThickness] = useStore((state) => [fromMM(state.frameThickness)]);
  const { flange } = frame;

  const alignment = frame.flangeAlignment || COMPONENT_ALIGNMENT.LEFT;

  const width = fromMM(frame.width);
  const height = fromMM(frame.height);
  const length = fromMM(frame.length);

  if (flangeWidth == undefined) flangeWidth = fromMM(flange?.width ?? frame.width);
  if (flangeHeight == undefined) flangeHeight = fromMM(flange?.height ?? frame.height);
  if (flangeLength == undefined) flangeLength = fromMM(flange?.length ?? FlangeProperties.length);
  if (flangeDistanceFromTop == undefined) flangeDistanceFromTop = fromMM(flange?.flangeDistanceFromTop ?? 0);

  const shape = flange?.shape || FlangeProperties.shape.value;
  const size = flange?.size || FlangeProperties.size;

  const hasFrame = frame.hasFrame;
  const isCurved = shape === "Curved";
  const componentLength = isCurved ? length : -0.01;
  const insideFrame = isCurved;

  const position = [
    alignment === COMPONENT_ALIGNMENT.TOP
      ? 0
      : align(((hasFrame === false || insideFrame ? -1 : 1) * alignment * flangeLength) / 2, fromMM(frame.length) + frameThickness * 2, flangeLength, alignment),
    alignment === COMPONENT_ALIGNMENT.TOP ? frameThickness + height / 2 - 0.005 : isCurved ? frameThickness : 0,
    0,
  ];

  var extrudeSettings = {
    depth: flangeLength,
    steps: 1,
    bevelEnabled: false,
    curveSegments: 16,
  };

  const geom = useMemo(() => {
    return isCurved
      ? buildCurvedDuct(length - 0.05, height - 0.05, length, height, width, 0.01)
      : buildDuctPlateWithHole(
          (alignment === COMPONENT_ALIGNMENT.TOP ? length : height) * 0.999, // - frameThickness * 2,
          (alignment === COMPONENT_ALIGNMENT.TOP ? width : width) * 0.999, //- frameThickness * 2,
          shape === "Radial",
          flangeWidth,
          flangeHeight,
          (width * size) / 2,
          flangeDistanceFromTop
        );
  }, [height, width, shape, size, length, flangeDistanceFromTop, alignment]);

  const duct_geometry = useMemo(() => {
    return buildDuct(
      alignment === COMPONENT_ALIGNMENT.TOP ? length : height, // - frameThickness,
      alignment === COMPONENT_ALIGNMENT.TOP ? width : width, // - frameThickness,
      shape === "Radial",
      flangeWidth,
      flangeHeight,
      (width * size) / 2,
      flangeDistanceFromTop
    );
  }, [height, length, width, shape, size, alignment, flangeDistanceFromTop]);

  return (
    <>
      <group
        rotation={[
          alignment === COMPONENT_ALIGNMENT.TOP ? -Math.PI / 2 : 0,
          alignment === COMPONENT_ALIGNMENT.TOP ? 0 : alignment !== COMPONENT_ALIGNMENT.LEFT ? Math.PI / 2 : -Math.PI / 2,
          alignment === COMPONENT_ALIGNMENT.TOP ? -Math.PI / 2 : 0,
        ]}
        position={position}
      >
        <mesh geometry={geom}>
          <meshStandardMaterial color={getColour("Grey")} />
          {isCurved && (
            <Edges scale={1} renderOrder={1000}>
              <meshBasicMaterial transparent color="Grey" widthTest={true} />
            </Edges>
          )}
        </mesh>
        {!isCurved && (
          <mesh>
            <extrudeGeometry args={[duct_geometry, extrudeSettings]} />
            <meshStandardMaterial color={getColour("Grey")} side={THREE.DoubleSide} />
            <Edges scale={1} renderOrder={1000}>
              <meshBasicMaterial transparent color="Grey" widthTest={true} />
            </Edges>
          </mesh>
        )}
      </group>
    </>
  );
}
