import { usePricing, useConfigurator } from "../../hooks/useQueries";
import { toUpper, sumBy, round } from "lodash";
import Button from "../shared/Button";
import { useState } from "react";
import { useUser } from "../../hooks/useQueries";

export default function Pricing({ ventilationUnitId }) {
  //   const { selectedUnitModelName } = useConfigurator();
  const { pricingList } = usePricing(ventilationUnitId);
  const { user } = useUser();

  const [showSellingPrice, setShowSellingPrice] = useState(user?.userGroupId == 1 ?? true);
  function onTogglePriceHandler() {
    setShowSellingPrice(!showSellingPrice);
  }

  return (
    <div className="divide-y divide-gray-200">
      {user?.userGroupId == 2 && (
        <div className="m-2">
          <Button
            text={showSellingPrice == true ? "Show Cost price" : "Show Selling Price"}
            buttonSize="xs"
            buttoncolor={"primary"}
            cssclass="!rounded-md"
            onClick={() => onTogglePriceHandler()}
          />
        </div>
      )}

      {/* <TechnicalHeader ventilationUnitId={ventilationUnitId}></TechnicalHeader> */}
      <div className="pt-0 p-3">
        {pricingList &&
          pricingList.map((ps, index) => (
            <div key={index} className="mt-3">
              <table class="min-w-full divide-y divide-gray-300 text-sm">
                <thead class="divide-y divide-gray-300">
                  <tr>
                    <th class="text-left w-1/4">{toUpper(ps.description)}</th>
                    <th class="w-40">Qty</th>
                    <th class="w-40">Unit</th>
                    <th class="w-40">Weight (Kg)</th>
                    <th class="w-40">{showSellingPrice ? "SP (AED)" : "Cost (AED)"}</th>
                    <th class="w-40">Total Wgt (Kg)</th>
                    <th class="w-40">{showSellingPrice ? "SP (AED)" : "Total Cost (AED)"}</th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  {ps.pricingItems.map((pi, index) => (
                    <tr>
                      <td class="font-semibold">{pi.description}</td>
                      <td class="text-center">{pi.quantity}</td>
                      <td class="text-center">{pi.unit}</td>
                      <td class="text-center">{pi.weight}</td>
                      <td class="text-center">{showSellingPrice ? pi.sellingPrice : pi.cost}</td>
                      <td class="text-center">{pi.totalWeight}</td>
                      <td class="text-center">{showSellingPrice ? pi.totalSellingPrice : pi.totalCost}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td class="font-semibold">SECTION TOTAL</td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center">{ps.totalWeight}</td>
                    <td class="text-center">{showSellingPrice ? ps.totalPrice : ps.totalCost}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          ))}
        {pricingList && (
          <table class="min-w-full divide-y divide-gray-300 border-y mt-3 text-sm">
            <thead class="divide-y divide-gray-300">
              <tr>
                <th class="text-left w-1/4">TOTALS</th>
                <th class="w-40">Qty</th>
                <th class="w-40">Unit</th>
                <th class="w-40">Weight(Kg)</th>
                <th class="w-40">{showSellingPrice ? "SP (AED)" : "Cost (AED)"}</th>
                <th class="w-40">Total Wgt (Kg)</th>
                <th class="w-40">{showSellingPrice ? "SP (AED)" : "Total Cost (AED)"}</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr>
                <td className="font-semibold">UNIT TOTAL</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-center">{round(sumBy(pricingList, "totalWeight"))}</td>
                <td class="text-center">{round(sumBy(pricingList, showSellingPrice ? "totalPrice" : "totalCost"))}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
