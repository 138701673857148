import { Fragment, useEffect, useMemo, useRef } from "react";
import { Edges } from "@react-three/drei";
import * as THREE from "three";
import { buildDuct, buildESPConeGeometry, buildHollowFrame, buildPanel } from "../Utils/geometryUtils";
import { fromMM } from "../Utils/frameUtils";
import ESPProperties from "./Properties/ESPProperties";
import { align } from "../Utils/frameUtils";
import { COMPONENT_ALIGNMENT } from "../Constants";
import { useStore } from "../Store/zustandStore";
import { ACCESS_SIDE } from "../Constants";

export default function ESP({ frame }) {
  const [frameThickness] = useStore((state) => [fromMM(state.frameThickness)]);

  const { esp } = frame;

  const { accessSide } = frame;

  const width = fromMM(esp?.width || ESPProperties.width);
  const height = fromMM(esp?.height || ESPProperties.height);
  const length = fromMM(esp?.length || ESPProperties.length);

  const columns = esp?.columns || ESPProperties.columns;
  const rows = esp?.rows || ESPProperties.rows;

  const frameWidth = fromMM(frame.width);
  const frameHeight = fromMM(frame.height);
  const frameLength = fromMM(frame.length);

  const hasAutoWasher = esp?.hasAutoWasher != undefined ? esp.hasAutoWasher : ESPProperties.hasAutoWasher;

  const controlBoxWidth = fromMM(esp?.controlBoxWidth || ESPProperties.controlBoxWidth);
  const controlBoxHeight = fromMM(esp?.controlBoxHeight || ESPProperties.controlBoxHeight);
  const controlBoxLength = fromMM(esp?.controlBoxLength || ESPProperties.controlBoxLength);

  const backControlBoxWidth = fromMM(esp?.backControlBoxWidth || ESPProperties.backControlBoxWidth);
  const backControlBoxHeight = fromMM(esp?.backControlBoxHeight || ESPProperties.backControlBoxHeight);
  const backControlBoxLength = fromMM(esp?.backControlBoxLength || ESPProperties.backControlBoxLength);

  const autoWasherBoxWidth = fromMM(esp?.autoWasherBoxWidth || ESPProperties.autoWasherBoxWidth);
  const autoWasherBoxHeight = fromMM(esp?.autoWasherBoxHeight || ESPProperties.autoWasherBoxHeight);
  const autoWasherBoxLength = fromMM(esp?.autoWasherBoxLength || ESPProperties.autoWasherBoxLength);

  const baseFrameLength = length * 1.1;
  const baseFrameHeight = fromMM(30);
  const baseFrameWidth = frameWidth;

  const boxFrameThickness = fromMM(esp?.frame || ESPProperties.frame);
  const position = [
    align(0, frameLength, length, frame.componentAlignment),
    align(0, frameHeight, height + baseFrameHeight * 2, COMPONENT_ALIGNMENT.BOTTOM),
    align(0, frameWidth, width + backControlBoxWidth * 2, COMPONENT_ALIGNMENT.BACK),
  ];

  const backControlBoxGeom = useMemo(() => {
    return buildPanel(backControlBoxLength, backControlBoxHeight, backControlBoxWidth);
  }, [backControlBoxLength, backControlBoxHeight, backControlBoxWidth]);

  const autoWasherBoxGeom = useMemo(() => {
    return buildPanel(autoWasherBoxLength, autoWasherBoxHeight, autoWasherBoxWidth);
  }, [autoWasherBoxLength, autoWasherBoxHeight, autoWasherBoxWidth]);

  const bottom_offset = fromMM(150);
  const autoWasherBoxPosition = [0, -frameHeight / 2 + autoWasherBoxHeight / 2 + bottom_offset, -frameWidth / 2 - autoWasherBoxWidth / 2 - frameThickness - fromMM(100)];

  const coneGeom = useMemo(() => {
    // top
    return buildESPConeGeometry([
      [
        new THREE.Vector3(-frameLength / 2, frameHeight / 2, -frameWidth / 2),
        frameWidth,
        0,
        new THREE.Vector3(-length / 2, position[1] + (height - boxFrameThickness * 2) / 2, position[2] - (width - boxFrameThickness * 2) / 2),
        width - boxFrameThickness * 2,
        0,
      ],
      // bottom
      [
        new THREE.Vector3(-frameLength / 2, -frameHeight / 2, -frameWidth / 2),
        frameWidth,
        0,
        new THREE.Vector3(-length / 2, position[1] - (height - boxFrameThickness * 2) / 2, position[2] - (width - boxFrameThickness * 2) / 2),
        width - boxFrameThickness * 2,
        0,
      ],
      // back
      [
        new THREE.Vector3(-frameLength / 2, frameHeight / 2, -frameWidth / 2),
        0,
        -frameHeight,
        new THREE.Vector3(-length / 2, position[1] + (height - boxFrameThickness * 2) / 2, position[2] - (width - boxFrameThickness * 2) / 2),
        0,
        -(height - boxFrameThickness * 2),
      ],
      // front
      [
        new THREE.Vector3(-frameLength / 2, frameHeight / 2, frameWidth / 2),
        0,
        -frameHeight,
        new THREE.Vector3(-length / 2, position[1] + (height - boxFrameThickness * 2) / 2, position[2] + (width - boxFrameThickness * 2) / 2),
        0,
        -(height - boxFrameThickness * 2),
      ],
    ]);
  }, [frameHeight, frameLength, frameWidth]);

  const espGroup = useRef();

  useEffect(() => {
    if (espGroup.current) {
      if (accessSide == ACCESS_SIDE.LEFT) {
        espGroup.current.applyMatrix4(new THREE.Matrix4().makeRotationY(90 * (Math.PI / 90)));
      }
    }
  }, [accessSide]);

  return (
    <group dispose={null} ref={espGroup}>
      {hasAutoWasher && (
        <mesh geometry={autoWasherBoxGeom} position={autoWasherBoxPosition}>
          <meshStandardMaterial color={"Grey"} side={THREE.DoubleSide} />
          <Edges scale={1} renderOrder={1000}>
            <meshBasicMaterial transparent color="Grey" widthTest={true} />
          </Edges>
        </mesh>
      )}
      <mesh geometry={backControlBoxGeom} position={[0, position[1], position[2] - width / 2 - backControlBoxWidth / 2]}>
        <meshStandardMaterial color={"Grey"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="Grey" widthTest={true} />
        </Edges>
      </mesh>
      <ESPUnits
        position={position}
        verticalCount={rows}
        horizontalCount={columns}
        width={width}
        height={height}
        length={length}
        controlBoxLength={controlBoxLength}
        controlBoxHeight={controlBoxHeight}
        controlBoxWidth={controlBoxWidth}
        boxFrameThickness={boxFrameThickness}
      ></ESPUnits>
      <mesh geometry={coneGeom}>
        <meshStandardMaterial color={"DarkGrey"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="DarkGrey" widthTest={true} />
        </Edges>
      </mesh>

      <mesh position={[0, align(0, frameHeight, baseFrameHeight, COMPONENT_ALIGNMENT.BOTTOM), 0]}>
        <boxGeometry args={[baseFrameLength, baseFrameHeight, baseFrameWidth]} />
        <meshStandardMaterial color={"Grey"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="Grey" widthTest={true} />
        </Edges>
      </mesh>
    </group>
  );
}

function ESPUnits({ position, verticalCount = 1, horizontalCount = 1, width, height, length, controlBoxLength, controlBoxHeight, controlBoxWidth, boxFrameThickness }) {
  const espUnits = [];
  const espLength = length / horizontalCount;
  const espHeight = height / verticalCount;
  let initialY = align(0, height, espHeight, COMPONENT_ALIGNMENT.BOTTOM);
  let initialX = align(0, length, espLength, COMPONENT_ALIGNMENT.LEFT);

  for (let h = 0; h < horizontalCount; h++)
    for (let v = 0; v < verticalCount; v++) {
      espUnits.push(
        <ESPUnit
          position={[initialX + h * espLength, initialY + v * espHeight, 0]}
          width={width}
          height={espHeight}
          length={espLength}
          controlBoxLength={controlBoxLength}
          controlBoxHeight={controlBoxHeight / 2}
          controlBoxWidth={controlBoxWidth}
          boxFrameThickness={boxFrameThickness}
        />
      );
    }

  return (
    <group position={position}>
      {espUnits.map((esp, i) => (
        <Fragment key={i}>{esp}</Fragment>
      ))}
    </group>
  );
}

function ESPUnit({ position, width, height, length, controlBoxLength, controlBoxHeight, controlBoxWidth, boxFrameThickness }) {
  const controlBoxFrontPanel = fromMM(10);
  const controlBoxFrontPanelWhite = controlBoxLength * 0.75;
  const controlBoxRedKnobRadius = fromMM(20);
  const controlBoxDial = fromMM(40);

  const topCylinderRadius = fromMM(20);

  const lip = fromMM(20);
  var extrudeSettings = {
    depth: lip,
    steps: 1,
    bevelEnabled: false,
    curveSegments: 16,
  };

  const lipGeom = useMemo(() => {
    return buildDuct(height, width, false, width - boxFrameThickness * 2, height - boxFrameThickness * 2, null, boxFrameThickness);
  }, [height, width]);

  const espBoxGeom = useMemo(() => {
    return buildHollowFrame(length, height, width, boxFrameThickness);
  }, [length, height, width]);

  const controlBoxGeom = useMemo(() => {
    return buildPanel(controlBoxLength, controlBoxHeight, controlBoxWidth - controlBoxFrontPanel);
  }, [controlBoxLength, controlBoxHeight, controlBoxWidth]);

  const controlBoxFrontPanelGeom = useMemo(() => {
    return buildPanel(controlBoxLength, controlBoxHeight, controlBoxFrontPanel);
  }, [controlBoxLength, controlBoxHeight]);

  return (
    <group dispose={null} position={position}>
      <mesh geometry={espBoxGeom}>
        <meshStandardMaterial color={"SteelBlue"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="SteelBlue" widthTest={true} />
        </Edges>
      </mesh>
      <mesh>
        <boxGeometry args={[length - boxFrameThickness, height - boxFrameThickness, width - boxFrameThickness]} />
        <meshStandardMaterial color={"White"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>
      <mesh geometry={controlBoxGeom} position={[0, 0, width / 2 + (controlBoxWidth - controlBoxFrontPanel) / 2]}>
        <meshStandardMaterial color={"SteelBlue"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="SteelBlue" widthTest={true} />
        </Edges>
      </mesh>
      <mesh geometry={controlBoxFrontPanelGeom} position={[0, 0, width / 2 + (controlBoxWidth - controlBoxFrontPanel) + controlBoxFrontPanel / 2]}>
        <meshStandardMaterial color={"#FFC300"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#FFC300" widthTest={true} />
        </Edges>
      </mesh>
      <group position={[0, fromMM(20), width / 2 + (controlBoxWidth - controlBoxFrontPanel) + controlBoxFrontPanel + fromMM(5) / 2]}>
        <mesh>
          <boxGeometry args={[controlBoxFrontPanelWhite, controlBoxLength * 0.5, fromMM(5)]} />
          <meshStandardMaterial color={"White"} />
          <Edges scale={1} renderOrder={1000}>
            <meshBasicMaterial transparent color="DarkGrey" widthTest={true} />
          </Edges>
        </mesh>
        <mesh
          position={[align(0, controlBoxFrontPanelWhite, controlBoxRedKnobRadius * 2, COMPONENT_ALIGNMENT.LEFT) + controlBoxFrontPanelWhite / 4, 0, fromMM(10) / 2]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <cylinderGeometry args={[controlBoxRedKnobRadius, controlBoxRedKnobRadius, fromMM(10), 32]} />
          <meshStandardMaterial color={"Red"} />
        </mesh>
        <mesh position={[align(0, controlBoxFrontPanelWhite, controlBoxDial, COMPONENT_ALIGNMENT.RIGHT) - controlBoxFrontPanelWhite / 4, 0, fromMM(10) / 2]}>
          <boxGeometry args={[controlBoxDial, controlBoxDial, fromMM(10)]} />
          <meshStandardMaterial color={"DarkGrey"} />
        </mesh>
      </group>
      <mesh position={[length / 2, 0, 0]} rotation={[0, Math.PI / 2, 0]}>
        <extrudeGeometry args={[lipGeom, extrudeSettings]} />
        <meshStandardMaterial color={"SteelBlue"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="SteelBlue" widthTest={true} />
        </Edges>
      </mesh>
      <mesh position={[-length / 2 - lip, 0, 0]} rotation={[0, Math.PI / 2, 0]}>
        <extrudeGeometry args={[lipGeom, extrudeSettings]} />
        <meshStandardMaterial color={"SteelBlue"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="SteelBlue" widthTest={true} />
        </Edges>
      </mesh>
      <mesh
        position={[
          align(0, length, topCylinderRadius * 2, COMPONENT_ALIGNMENT.LEFT) + fromMM(20),
          height / 2,
          align(0, width, topCylinderRadius * 2, COMPONENT_ALIGNMENT.TOP) - fromMM(20),
        ]}
      >
        <cylinderGeometry args={[topCylinderRadius, topCylinderRadius, fromMM(10), 32]} />
        <meshStandardMaterial color={"SteelBlue"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>
      <mesh
        position={[
          align(0, length, topCylinderRadius * 2, COMPONENT_ALIGNMENT.RIGHT) - fromMM(20),
          height / 2,
          align(0, width, topCylinderRadius * 2, COMPONENT_ALIGNMENT.TOP) - fromMM(20),
        ]}
      >
        <cylinderGeometry args={[topCylinderRadius, topCylinderRadius, fromMM(10), 32]} />
        <meshStandardMaterial color={"SteelBlue"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>
      <mesh
        position={[
          align(0, length, topCylinderRadius * 2, COMPONENT_ALIGNMENT.LEFT) + fromMM(20),
          height / 2,
          align(0, width, topCylinderRadius * 2, COMPONENT_ALIGNMENT.BOTTOM) + fromMM(20),
        ]}
      >
        <cylinderGeometry args={[topCylinderRadius, topCylinderRadius, fromMM(10), 32]} />
        <meshStandardMaterial color={"SteelBlue"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>
      <mesh
        position={[
          align(0, length, topCylinderRadius * 2, COMPONENT_ALIGNMENT.RIGHT) - fromMM(20),
          height / 2,
          align(0, width, topCylinderRadius * 2, COMPONENT_ALIGNMENT.BOTTOM) + fromMM(20),
        ]}
      >
        <cylinderGeometry args={[topCylinderRadius, topCylinderRadius, fromMM(10), 32]} />
        <meshStandardMaterial color={"SteelBlue"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>
    </group>
  );
}
