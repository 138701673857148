import { toUpper } from "lodash";
import { useTechnical } from "../../hooks/useQueries";
import TechnicalSection from "./TechnicalSection";
import TechnicalSectionItem from "./TechnicalSectionItem";
import TechnicalSectionItemRow from "./TechnicalSectionItemRow";
import TechnicalSubSection from "./TechnicalSubSection";

export default function Technical({ ventilationUnitId }) {
  const { technicalSections, convertTechnicalSectionItems } = useTechnical();

  return (
    <div className="divide-y divide-gray-200 ">
      <div className="pt-0 p-3">
        {technicalSections &&
          technicalSections.map((technicalSection, index) => (
            <div key={index} className="mt-3">
              <TechnicalSection heading={toUpper(technicalSection.heading)}>
                {technicalSection.subSections?.length > 0 ? 
                (
                  // technicalSection.items?.length > 0 &&
                  // convertTechnicalSectionItems(technicalSection)?.map((rows, index) => (
                  //   <TechnicalSectionItemRow columnCount={rows.length}>
                  //     {rows.map((column, index) => (
                  //       <TechnicalSectionItem technicalSectionItem={column}></TechnicalSectionItem>
                  //     ))}
                  //   </TechnicalSectionItemRow>                    
                  // ))
                  (
                    technicalSection.subSections.map((t, index) => (
                      <TechnicalSubSection heading={toUpper(t.heading)}>
                        {convertTechnicalSectionItems(t).map((rows, index) => (
                          <TechnicalSectionItemRow columnCount={rows.length}>
                            {rows.map((column, index) => (
                              <TechnicalSectionItem technicalSectionItem={column}></TechnicalSectionItem>
                            ))}
                          </TechnicalSectionItemRow>
                        ))}
                      </TechnicalSubSection>
                    ))
                  )
                ) : (
                  <div className="py-2">
                    {convertTechnicalSectionItems(technicalSection).map((rows, index) => (
                      <TechnicalSectionItemRow columnCount={rows.length}>
                        {rows.map((column, index) => (
                          <TechnicalSectionItem technicalSectionItem={column}></TechnicalSectionItem>
                        ))}
                      </TechnicalSectionItemRow>
                    ))}
                  </div>
                )}
              </TechnicalSection>
            </div>
          ))}
      </div>
    </div>
  );
}
