import { Edges } from "@react-three/drei";
import { COMPONENT_ALIGNMENT } from "../Constants";
import { align, fromMM } from "../Utils/frameUtils";
import { useMemo } from "react";

export default function CarbonFilter({ frame }) {
  const { carbonFilter } = frame;
  const totalFilterWidth = fromMM(carbonFilter?.width || frame.width);
  const totalFilterLength = fromMM(carbonFilter?.length || frame.length);
  const filterDepth = fromMM(carbonFilter?.depth || 0.01);
  const totalFilterHeight = fromMM(carbonFilter?.height || frame.height);
  const columns = carbonFilter?.columns || [1];
  const rows = carbonFilter?.rows || [0.5, 0.5];
  const requiredFilters = 2;
  const slopeHeight = totalFilterHeight / requiredFilters;

  const filters = useMemo(() => {
    return createFilters();
  }, [requiredFilters, slopeHeight, totalFilterHeight, totalFilterLength, totalFilterWidth, columns, filterDepth]);

  function createFilters() {
    const filters = [];

    const initalZ = align(0, totalFilterWidth, columns[0] * totalFilterWidth, COMPONENT_ALIGNMENT.FRONT) + (columns[0] * totalFilterWidth) / 2;

    let z = initalZ;

    let y = align(0, totalFilterHeight, rows[0] * totalFilterHeight, COMPONENT_ALIGNMENT.BOTTOM) - (rows[0] * totalFilterHeight) / 2;

    const angleFilterRowsWithoutGap = rows.filter(r => r > 0);
    let sa = angleFilterRowsWithoutGap.length % 2 == 0 ? -1 : 1;
    for (let r = 0; r < rows.length; r++) {
      if(rows[r] > 0) sa *= -1;
      const filterHeight = rows[r] * totalFilterHeight;
      const lengthOfFilter = Math.sqrt(filterHeight ** 2 + totalFilterLength ** 2);
      const angleOfFilter = Math.atan(filterHeight / totalFilterLength) * sa;
      //const angleOfFilter = Math.atan(75) * (r % 2 == 0 ? -1 : 1);

      for (let c = 0; c < columns.length; c++) {
        if (rows[r] > 0 && columns[c] > 0) {
          filters.push(
            <FilterSlope
              key={`carbon-filter-${r}-${c}`}
              //position={[0, slopeHeight / 2 + i * slopeHeight - totalFilterHeight / 2, z - (columns[c] * totalFilterWidth) / 2]}
              position={[0, y + (rows[r] * totalFilterHeight) / 2, z - (columns[c] * totalFilterWidth) / 2]}
              width={totalFilterWidth * (columns[c])}
              lengthOfFilter={lengthOfFilter}
              angleOfFilter={angleOfFilter}
              filterDepth={filterDepth}
            />
          );
        }

        z -= Math.abs(columns[c]) * totalFilterWidth;
      }
      y += Math.abs(rows[r]) * totalFilterHeight;
      z = initalZ;
    }

    return filters;
  }

  return (
    <group dispose={null} rotation-y={frame.componentAlignment == COMPONENT_ALIGNMENT.RIGHT ? Math.PI : 0}>
      {filters}
    </group>
  );
}

function FilterSlope({ width, position, lengthOfFilter, angleOfFilter, filterDepth }) {
  return (
    <group dispose={null} position={position}>
      <mesh castShadow receiveShadow rotation-z={angleOfFilter} position={[0, 0, 0]}>
        <boxGeometry args={[lengthOfFilter, filterDepth, width]} />
        <meshStandardMaterial color={"#333333"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" depthTest={true} />
        </Edges>
      </mesh>
      {/* <mesh castShadow receiveShadow rotation-z={-angleOfFilter} position={[0, height / 2, 0]}>
        <boxGeometry args={[lengthOfFilter, 0.01, width]} />
        <meshStandardMaterial color={"#333333"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" depthTest={true} />
        </Edges>
      </mesh> */}
    </group>
  );
}
