import Button from "../shared/Button";
import { useProject } from "../../hooks/useQueries";

export default function ProjectHeader({saveProject:saveProject, getProjects,onNewProject} ) {

  return (
    <div className="border-b border-gray-200 p-3">
      <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md mr-3" onClick={getProjects}>
        Projects
      </Button>
      <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md mr-3" onClick={onNewProject}>
        New Project
      </Button>
      <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md mr-3" onClick={saveProject}>
        Save
      </Button>
    </div>
  );
}
