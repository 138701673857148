import { useState, useEffect } from "react";
import NavBurger from "./NavBurger";
import logo from "../../assets/logo.png";
import { useNav, useUser } from "../../hooks/useQueries";
import NavMenu from "./NavMenu";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import { useQueryClient } from "react-query";
import { userStore } from "../../stores/userStore";
import { Outlet } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout(props) {
  const [isAuthenticated] = userStore((state) => [state.isAuthenticated]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { navigations, updateCurrentNavigation } = useNav();
  const { refreshToken } = useUser();
  const navChangedHandler = (key) => {
    updateCurrentNavigation(key);
    setSidebarOpen(false);
  };

  useEffect(() => {
    refreshToken();
  }, []);

  return (
    <div className="flex">
      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0  flex-col sticky top-0 self-start h-screen">
        <div className="flex w-48 flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                <img className="h-20 w-auto" src={logo} alt="logo" />
              </div>
              <NavMenu navigation={navigations} setSidebarOpen={() => setSidebarOpen(false)} sidebarOpen={sidebarOpen} navChangedHandler={navChangedHandler}></NavMenu>
            </div>

            {/* <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                <a href="#" className="group block w-full flex-shrink-0">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-9 w-9 rounded-full"
                        src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                        Whitney Francis
                      </p>
                      <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                        View profile
                      </p>
                    </div>
                  </div>
                </a>
              </div> */}
          </div>
        </div>
        <div className="flex flex-shrink-0 border-t border-gray-200 p-4 mt-auto">{isAuthenticated ? <LogoutButton></LogoutButton> : <LoginButton></LoginButton>}</div>
      </div>
      <div className="flex min-w-0 flex-1 flex-col bg-gray-100">
        <NavBurger setSidebarOpen={() => setSidebarOpen(true)} />
        <main>
          <div className="p-2">
            {/* Start main area*/}
            {/* <Container>{props.children}</Container> */}
            <Outlet />
            {/* {props.children} */}
            {/* End main area */}
          </div>
        </main>
      </div>
    </div>
  );
}
