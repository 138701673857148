import { QueryClient } from "react-query";
import { toast } from "react-toastify";
import { isString } from "lodash";

const queryErrorHandler = (error) => {
  const msg = error instanceof Error ? error.response.data && isString(error.response.data) ? error.response.data : error.message : "error connecting to server";
  toast(msg);
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: queryErrorHandler,
      //         staleTime: 600000, // 10 minutes
      //         cacheTime: 900000, // default cacheTime is 5 minutes; doesn't make sense for staleTime to exceed cacheTime
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
    mutations: {
      onError: queryErrorHandler,
    },
  },
});
