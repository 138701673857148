import { useMemo } from "react";
import { fromMM } from "../Utils/frameUtils";
import { buildOdourPipe, buildPanel } from "../Utils/geometryUtils";
import OdourNeutraliserProperties from "./Properties/OdourNeutraliserProperties";
import { Edges } from "@react-three/drei";
import { useStore } from "../Store/zustandStore";
import * as THREE from "three";

export default function OdourNeutraliser({ frame }) {
  const [frameThickness] = useStore((state) => [fromMM(state.frameThickness)]);

  const { odour, accessSide } = frame;

  const frameHeight = fromMM(frame.height);
  const frameWidth = fromMM(frame.width);

  const bottom_offset = fromMM(150);

  const boxLength = fromMM(odour?.boxLength || OdourNeutraliserProperties.boxLength);
  const boxHeight = fromMM(odour?.boxHeight || OdourNeutraliserProperties.boxHeight);
  const boxWidth = fromMM(odour?.boxWidth || OdourNeutraliserProperties.boxWidth);

  const pipeWidth = boxLength / 3;
  const pipeHeight = boxHeight / 2;
  const tubeThickness = 0.015;
  const intrudingLength = frameWidth / 4;
  const downwardSlope = 0.09;

  const sprayRadius = 0.1;
  const sprayLength = 0.2;

  const boxPosition = [0, -frameHeight / 2 + boxHeight / 2 + bottom_offset, -frameWidth / 2 - boxLength / 2 - frameThickness];
  const pipePosition = [boxPosition[0], boxPosition[1] + boxHeight / 2 + pipeHeight / 2, boxPosition[2]];
  const sprayPosition = [0, pipePosition[1] + pipeHeight / 2 - downwardSlope, -frameWidth / 2 + sprayLength / 2 + intrudingLength];

  const geom = useMemo(() => {
    return buildPanel(boxWidth, boxHeight,boxLength);
  }, [boxLength, boxHeight, boxWidth]);

  const pipeGeom = useMemo(() => {
    return buildOdourPipe(pipeWidth, pipeHeight, tubeThickness, boxWidth / 2, frameThickness + intrudingLength, downwardSlope);
  }, [pipeWidth, pipeHeight, tubeThickness, boxWidth, frameThickness]);

  return (
    <group>
      <mesh geometry={geom} position={boxPosition} receiveShadow castShadow>
        <meshStandardMaterial color="Grey" />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" depthTest={true} />
        </Edges>
      </mesh>

      <mesh geometry={pipeGeom} position={pipePosition} receiveShadow castShadow>
        <meshStandardMaterial color="Black" side={THREE.DoubleSide} />
        {/* <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="Grey" depthTest={true} />
        </Edges> */}
      </mesh>

      <mesh rotation={[-Math.PI / 2, 0, 0]} position={sprayPosition} receiveShadow castShadow>
        <coneGeometry args={[sprayRadius, sprayLength, 8]} />
        <meshLambertMaterial color={"Pink"} transparent opacity={0.5} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="Pink" depthTest={true} />
        </Edges>
      </mesh>
    </group>
  );
}
