import Selection from "./views/Selection";
import PageNotFound from "./components/PageNotFound";
import Project from "./views/Project";
import Login from "./views/Login";
import Register from "./views/Register";
import UserProfile from "./views/UserProfile";
import { createBrowserRouter } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Error from "./components/Error";
import { redirect } from "react-router-dom";
import Users from "./components/admin/Users";
import { isEmpty } from "lodash";

const isAuthenticated = () => {
  if (isEmpty(localStorage.getItem("user"))) {
    return redirect("/login");
  }
};

const isAdmin = () => {
  var user = JSON.parse(localStorage.getItem("user"));
  if (user == null || user.userGroup != 2) {
    return redirect("/selection");
  }
};

export const Router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        path: "selection",
        element: <Selection />,
        loader: isAuthenticated,
      },
      {
        path: "project",
        element: <Project />,
        loader: isAuthenticated,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "verify-email",
        
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
  {
    path: "/user",
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        path: "profile",
        element: <UserProfile />,
        loader: isAuthenticated,
      },
    ],
  },
  {
    path: "/admin",
    element: <Layout />,
    errorElement: <Error />,    
    children: [
      {
        path: "users",
        element: <Users />,
        loader: isAuthenticated,
      },
    ],
  },
]);
