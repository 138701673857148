import Input from "../components/shared/Input";
import { useForm, useFormState } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUser } from "../hooks/useQueries";

export default function Register() {
  const { registerUser } = useUser();

  const validationSchema = yup.object().shape({
    title: yup.string().oneOf(["Mr","Mrs","Miss", "Ms", "Dr"], "Required"),
    password: yup.string().required("Required"),
    confirmPassword: yup
      .string()
      .required("Required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    email: yup.string().email().required("Required"),
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    company: yup.string(),
    jobTitle: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getFieldState,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: "", password: "" },
  });

  const onSubmit = (data, e) => {
    registerUser(data);
  };
  
  const onError = (errors, e) => console.log(errors, e);

  const titles = [
    { data: "Please select", value: "" },
    { data: "Mr", value: "Mr" },
    { data: "Mrs", value: "Mrs" },
    { data: "Miss", value: "Miss" },
    { data: "Ms", value: "Ms" },
    { data: "Dr", value: "Dr" },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="flex min-h-full flex-col justify-center py-4">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="text-center text-2xl tracking-tight text-gray-700 font-medium ">Create an account</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-6 px-6 shadow sm:rounded-lg">
            <div className="space-y-4">
              <Input label="Email address" elementtype="input" type="email" name="email" {...register("email")} error={errors.email} />
              {errors.email && <span className="text-red-500 text-xs">{errors.email.message}</span>}

              <Input label="Password" elementtype="input" type="password" {...register("password")} error={errors.password} />
              {errors.password && <span className="text-red-500 text-xs">{errors.password.message}</span>}

              <Input label="Confirm password" elementtype="input" type="password" {...register("confirmPassword")} error={errors.confirmPassword} />
              {errors.confirmPassword && <span className="text-red-500 text-xs">{errors.confirmPassword.message}</span>}

              <Input label="Title" elementtype="select" type="text" options={titles} {...register("title")} error={errors.title} />
              {errors.title && <span className="text-red-500 text-xs">{errors.title.message}</span>}

              <Input label="First name" elementtype="input" type="text" {...register("firstName")} error={errors.firstName} />
              {errors.firstName && <span className="text-red-500 text-xs">{errors.firstName.message}</span>}

              <Input label="Last name" elementtype="input" type="text" {...register("lastName")} error={errors.lastName} />
              {errors.lastName && <span className="text-red-500 text-xs">{errors.lastName.message}</span>}

              <Input label="Company" elementtype="input" type="text" {...register("company")} />
              <Input label="Job title" elementtype="input" type="text" {...register("jobTitle")} />

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-primary py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                >
                  Create account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
