import { Edges, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { buildBelt, buildCentrifugal, buildDuct, buildDuctPlateWithHole, buildHollowFrame } from "../Utils/geometryUtils";
import { useEffect, useMemo, useRef, useState } from "react";
import { align, fromMM } from "../Utils/frameUtils";
import { COMPONENT_ALIGNMENT, COMPONENT_SIDE } from "../Constants";
import Flange from "./Flange";
import { MathUtils } from "three";
import { useStore } from "../Store/zustandStore";

export default function Fan({ frame, selected }) {
  const [frameThickness] = useStore((state) => [fromMM(state.frameThickness)]);

  const frameLength = fromMM(frame.length);
  const frameHeight = fromMM(frame.height);
  const frameWidth = fromMM(frame.width);

  const fanWidth = fromMM(frame.fan?.width ?? 322);
  const fanSize = fromMM(frame.fan?.size ?? 550);
  const fanDistanceFromTop = fromMM(frame.fan?.distanceFromTop ?? 150);

  const fanOutletHeight = fromMM(frame.fan?.outletHeight ?? 422);
  const fanOutletLength = fromMM(frame.fan?.outletLength ?? 70);
  //const fanRotation = MathUtils.degToRad(frame.fan?.angle || 0);
  const fanRotation = MathUtils.degToRad(frame.fan?.angle || 0);

  const fanFrameLength = fanSize - fanOutletLength;

  const fanAlignment = frame.componentAlignment;
  const flangeAlignment = frame.flangeAlignment || COMPONENT_ALIGNMENT.LEFT;

  const flangeConnectorSize = [fanOutletHeight, flangeAlignment == COMPONENT_ALIGNMENT.TOP ? fanDistanceFromTop + frameThickness : frameThickness, fanWidth];
 // const flangeConnectorSize = [fanOutletHeight, flangeAlignment == COMPONENT_ALIGNMENT.TOP ? 0 : frameThickness, fanWidth];

  // Mounting

  const mountingFrameLength = fromMM(frame.fan?.mountingFrameLength ?? 850);
  const mountingFrameWidth = fromMM(frame.fan?.mountingFrameWidth ?? 80);

  const mountingFrameHeight = fromMM(frame.fan?.baseChannelHeight ?? 40);
  const isolatorHeight = fromMM(frame.fan?.isolatorHeight ?? 40);
  const fanChannelHeight = fromMM(frame.fan?.fanChannelHeight ?? 40);

  const fanFrameMountingPositionY = frameHeight / 2 + -(fanDistanceFromTop + fanSize + isolatorHeight / 2);

  const motorBaseLength = fromMM(frame.fan?.motorMountingLength ?? 300);
  const motorBaseWidth = fromMM(frame.fan?.motorMountingWidth ?? 300);
  const motorBaseHeight = fromMM(frame.fan?.motorMountingHeight ?? 40);

  const feetRadius = fromMM(frame.fan?.feetRadius ?? 20);
  const feetHeight = frameHeight - fanDistanceFromTop - fanSize - mountingFrameHeight - isolatorHeight;

  // Motor

  const motorRadius = fromMM(frame.fan?.motorRadius ?? 80);
  const motorLength = fromMM(frame.fan?.motorLength ?? 300);

  const motorShaftLength = fromMM(frame.fan?.motorShaftLength ?? 100);

  const pulleyThickness = fromMM(frame.fan?.pulleyThickness ?? 30);
  const fanPulleyRadius = fromMM(frame.fan?.fanPulleyRadius ?? 80);
  const motorPulleyRadius = fromMM(frame.fan?.motorPulleyRadius ?? 60);

  const motorMountingWidth = fanWidth + mountingFrameWidth * 2;

  // Positions

  const fanPosition = [align(0, frameLength, fanSize, fanAlignment), align(0, frameHeight, fanSize, COMPONENT_ALIGNMENT.TOP) - fanDistanceFromTop, 0];

  const flangeConnectorPosition = [
    flangeAlignment == COMPONENT_ALIGNMENT.TOP
      ? align(fanPosition[0], fanSize, fanOutletHeight, COMPONENT_ALIGNMENT.LEFT)
      : align(fanPosition[0], fanSize, -frameThickness, COMPONENT_ALIGNMENT.RIGHT),
    flangeAlignment == COMPONENT_ALIGNMENT.TOP
      ? align(0, frameHeight + frameThickness * 2, fanDistanceFromTop + frameThickness, COMPONENT_ALIGNMENT.TOP)
      : align(fanPosition[1], fanSize, fanOutletHeight, COMPONENT_ALIGNMENT.TOP),
    0,
  ];

  const motorBasePosition = [
    align(0, mountingFrameLength, motorBaseLength, COMPONENT_ALIGNMENT.LEFT),
    align(fanFrameMountingPositionY, mountingFrameHeight, -motorBaseHeight, COMPONENT_ALIGNMENT.TOP) + mountingFrameHeight,
    align(0, motorMountingWidth, motorBaseWidth, COMPONENT_ALIGNMENT.FRONT)
  ];

  const motorPosition = [motorBasePosition[0], align(motorBasePosition[1], motorBaseHeight, -motorRadius * 2, COMPONENT_ALIGNMENT.TOP), align(0, motorMountingWidth, motorBaseWidth, COMPONENT_ALIGNMENT.FRONT)];
  const pulleyPosition = frame.fan?.pulleyPosition ?? 1;

  const [fanPulleyPosition, setFanPulleyPosition] = useState([
    fanPosition[0],
    fanPosition[1] + -(fanSize * 0.25) / 2,
    motorPosition[2] + (motorLength / 2 + motorShaftLength) * pulleyPosition,
  ]);
  const motorPulleyPosition = [
    align(motorPosition[0] - fanOutletLength, frameLength, mountingFrameLength, fanAlignment),
    motorPosition[1],
    motorPosition[2] + (motorLength / 2 + motorShaftLength) * pulleyPosition,
  ];

  // Geometries

  const flangeConnectorGeometry = useMemo(() => {
    return buildHollowFrame(flangeConnectorSize[1], flangeConnectorSize[0], flangeConnectorSize[2], 0.01);
  }, [fanSize, fanOutletHeight, fanWidth, flangeAlignment]);

  const fan_geometry = useMemo(() => {
    return buildCentrifugal(fanSize,fanSize, fanWidth, fanOutletHeight, fanOutletLength, pulleyPosition);
  }, [fanSize, fanOutletHeight, fanWidth]);

  const fanShaftRef = useRef();
  useEffect(() => {
    if (fanShaftRef) {
      fanShaftRef.current.updateMatrixWorld();
      const matrix = fanShaftRef.current.matrix;
      const worldVector = fanShaftRef.current.position.clone();
      worldVector.x = 0;
      worldVector.y = -(fanSize * 0.25) / 2;
      worldVector.applyMatrix4(matrix);
      setFanPulleyPosition([worldVector.x, worldVector.y, motorPosition[2] + (motorLength / 2 + motorShaftLength) * pulleyPosition]);
    }
  }, [fanShaftRef, frameLength, fanRotation, fanAlignment]);

  return (
    <group>
      <group position={fanPosition} rotation={[0, 0, fanRotation]} ref={fanShaftRef}>
        {fan_geometry.map((g, i) => {
          return (
            <mesh key={i} geometry={g}>
              <meshStandardMaterial color={i == fan_geometry.length - 1 ? "Black" : "SlateGrey"} side={THREE.DoubleSide} />
              <Edges scale={1} renderOrder={1000}>
                <meshBasicMaterial transparent color="#526477" widthTest={true} />
              </Edges>
            </mesh>
          );
        })}
        <FanFrame width={fanFrameLength} height={fanSize} depth={fromMM(40)} position={[align(0, fanSize, fanFrameLength, COMPONENT_ALIGNMENT.LEFT), 0, fanWidth / 2]}></FanFrame>
        <FanFrame
          width={fanFrameLength}
          height={fanSize}
          depth={fromMM(40)}
          position={[align(0, fanSize, fanFrameLength, COMPONENT_ALIGNMENT.LEFT), 0, -fanWidth / 2]}
          rotation={[0, Math.PI, 0]}
        ></FanFrame>
      </group>

      <mesh position={flangeConnectorPosition} geometry={flangeConnectorGeometry} rotation={[0, 0, flangeAlignment == COMPONENT_ALIGNMENT.TOP ? Math.PI / 2 : 0]}>
        <meshStandardMaterial color={"DarkGrey"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#526477" widthTest={true} />
        </Edges>
      </mesh>

      <MotorPulleysAndBelt
        pulleyThickness={pulleyThickness}
        fanPulleyRadius={fanPulleyRadius}
        motorPulleyRadius={motorPulleyRadius}
        fanPulleyPosition={fanPulleyPosition}
        motorPulleyPosition={motorPulleyPosition}
      />

      <group position={[align(-fanOutletLength, frameLength, mountingFrameLength, fanAlignment), 0, 0]}>
        {/*Fan mounting*/}
        <MountingFrame
          length={mountingFrameLength}
          height={fanChannelHeight}
          width={mountingFrameWidth}
          position={[0, fanFrameMountingPositionY, align(0, fanWidth, -mountingFrameWidth, COMPONENT_ALIGNMENT.BACK)]}
        />
        <MountingFrame
          length={mountingFrameLength}
          height={fanChannelHeight}
          width={mountingFrameWidth}
          position={[0, fanFrameMountingPositionY, align(0, fanWidth, -mountingFrameWidth, COMPONENT_ALIGNMENT.FRONT)]}
        />

        {/*Motor mounting*/}
        <MountingFrame
          length={fanWidth + mountingFrameWidth * 2}
          height={mountingFrameHeight}
          width={mountingFrameWidth}
          position={[align(0, mountingFrameLength, mountingFrameWidth, COMPONENT_ALIGNMENT.LEFT), fanFrameMountingPositionY + mountingFrameHeight, 0]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <MountingFrame
          length={fanWidth + mountingFrameWidth * 2}
          height={mountingFrameHeight}
          width={mountingFrameWidth}
          position={[
            align(0, mountingFrameLength, mountingFrameWidth, COMPONENT_ALIGNMENT.LEFT) + motorBaseLength - mountingFrameWidth,
            fanFrameMountingPositionY + mountingFrameHeight,
            0,
          ]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <MotorBase position={motorBasePosition} length={motorBaseLength} height={motorBaseHeight} width={motorBaseWidth} />
        <Motor position={motorPosition} rotation={[Math.PI / 2, 0, 0]} radius={motorRadius} length={motorLength} shaftLength={motorShaftLength} shaftSide={pulleyPosition} />

        {/*Floor mounting and feet*/}
        <MountingFrame
          length={frameWidth}
          height={mountingFrameHeight}
          width={mountingFrameWidth}
          position={[align(0, mountingFrameLength, mountingFrameWidth, COMPONENT_ALIGNMENT.LEFT), align(0, frameHeight, mountingFrameHeight, COMPONENT_ALIGNMENT.BOTTOM), 0]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <MountingFrame
          length={frameWidth}
          height={mountingFrameHeight}
          width={mountingFrameWidth}
          position={[align(0, mountingFrameLength, mountingFrameWidth, COMPONENT_ALIGNMENT.RIGHT), align(0, frameHeight, mountingFrameHeight , COMPONENT_ALIGNMENT.BOTTOM), 0]}
          rotation={[0, Math.PI / 2, 0]}
        />
        <MountingFoot
          radius={feetRadius}
          height={feetHeight}
          position={[
            align(0, mountingFrameLength, mountingFrameWidth, COMPONENT_ALIGNMENT.RIGHT),
            align(0, frameHeight, feetHeight, COMPONENT_ALIGNMENT.BOTTOM) + mountingFrameHeight,
            align(0, fanWidth, -mountingFrameWidth, COMPONENT_ALIGNMENT.FRONT),
          ]}
        />
        <MountingFoot
          radius={feetRadius}
          height={feetHeight}
          position={[
            align(0, mountingFrameLength, mountingFrameWidth, COMPONENT_ALIGNMENT.RIGHT),
            align(0, frameHeight, feetHeight, COMPONENT_ALIGNMENT.BOTTOM) + mountingFrameHeight,
            align(0, fanWidth, -mountingFrameWidth, COMPONENT_ALIGNMENT.BACK),
          ]}
        />
        <MountingFoot
          radius={feetRadius}
          height={feetHeight}
          position={[
            align(0, mountingFrameLength, mountingFrameWidth, COMPONENT_ALIGNMENT.LEFT),
            align(0, frameHeight, feetHeight, COMPONENT_ALIGNMENT.BOTTOM) + mountingFrameHeight,
            align(0, fanWidth, -mountingFrameWidth, COMPONENT_ALIGNMENT.FRONT),
          ]}
        />
        <MountingFoot
          radius={feetRadius}
          height={feetHeight}
          position={[
            align(0, mountingFrameLength, mountingFrameWidth, COMPONENT_ALIGNMENT.LEFT),
            align(0, frameHeight, feetHeight, COMPONENT_ALIGNMENT.BOTTOM) + mountingFrameHeight,
            align(0, fanWidth, -mountingFrameWidth, COMPONENT_ALIGNMENT.BACK),
          ]}
        />
      </group>

      {frame.hasFlange && (
        <Flange
          frame={frame}
          getColour={() => (selected ? "Orange" : "DarkGrey")}
          flangeWidth={fanWidth}
          flangeHeight={fanOutletHeight}
          flangeDistanceFromTop={flangeAlignment == COMPONENT_ALIGNMENT.TOP ? fanSize - fanOutletHeight : fanDistanceFromTop}
        />
      )}
    </group>
  );
}

export function MotorPulleysAndBelt({ motorPulleyRadius, fanPulleyRadius, motorPulleyPosition, fanPulleyPosition, pulleyThickness }) {
  const beltThickness = fromMM(5);
  const beltGeometry = useMemo(() => {
    const startPoint = [fanPulleyPosition[0], fanPulleyPosition[1] + fanPulleyRadius - beltThickness, fanPulleyPosition[2]];
    const endPoint = [motorPulleyPosition[0], motorPulleyPosition[1] + motorPulleyRadius - beltThickness, motorPulleyPosition[2]];
    return buildBelt(startPoint, endPoint, beltThickness);
  }, [motorPulleyRadius, fanPulleyRadius, motorPulleyPosition, fanPulleyPosition]);

  const beltGeometryBottom = useMemo(() => {
    const startPoint = [fanPulleyPosition[0], fanPulleyPosition[1] - fanPulleyRadius + beltThickness, fanPulleyPosition[2]];
    const endPoint = [motorPulleyPosition[0], motorPulleyPosition[1] - motorPulleyRadius + beltThickness, motorPulleyPosition[2]];
    return buildBelt(startPoint, endPoint, beltThickness);
  }, [motorPulleyRadius, fanPulleyRadius, motorPulleyPosition, fanPulleyPosition]);

  return (
    <group>
      <mesh position={fanPulleyPosition} rotation={[Math.PI / 2, 0, 0]}>
        <cylinderGeometry args={[fanPulleyRadius, fanPulleyRadius, pulleyThickness, 32]} />
        <meshStandardMaterial color={"DarkGrey"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="Grey" widthTest={true} />
        </Edges>
      </mesh>

      <mesh position={motorPulleyPosition} rotation={[Math.PI / 2, 0, 0]}>
        <cylinderGeometry args={[motorPulleyRadius, motorPulleyRadius, pulleyThickness, 32]} />
        <meshStandardMaterial color={"DarkGrey"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="Grey" widthTest={true} />
        </Edges>
      </mesh>

      <mesh geometry={beltGeometry}>
        <meshStandardMaterial color={"Black"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="Grey" widthTest={true} />
        </Edges>
      </mesh>

      <mesh geometry={beltGeometryBottom}>
        <meshStandardMaterial color={"Black"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="Grey" widthTest={true} />
        </Edges>
      </mesh>
    </group>
  );
}

export function Motor({ position, rotation, radius, length, shaftLength, shaftSide }) {
  return (
    <group position={position}>
      <mesh rotation={rotation}>
        <cylinderGeometry args={[radius, radius, length, 32]} />
        <meshStandardMaterial color={"DarkGrey"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="Grey" widthTest={true} />
        </Edges>
      </mesh>
      <mesh position={[0, 0, (length / 2 + shaftLength / 2) * shaftSide]} rotation={rotation}>
        <cylinderGeometry args={[0.01, 0.01, shaftLength, 16]} />
        <meshStandardMaterial color={"Black"} />
      </mesh>
    </group>
  );
}

export function MotorBase({ position, length, width, height }) {
  return (
    <mesh position={position}>
      <boxGeometry args={[length, height, width]} />
      <meshStandardMaterial color={"DarkGrey"} />
      <Edges scale={1} renderOrder={1000}>
        <meshBasicMaterial transparent color="Grey" widthTest={true} />
      </Edges>
    </mesh>
  );
}

export function MountingFoot({ radius, height, position }) {
  return (
    <mesh position={position}>
      <cylinderGeometry args={[radius, radius, height, 16]} />
      <meshStandardMaterial color={"Black"} />
    </mesh>
  );
}

export function MountingFrame({ width, height, length, position = [0, 0, 0], rotation = [0, 0, 0] }) {
  return (
    <mesh position={position} rotation={rotation}>
      <boxGeometry args={[length, height, width]} />
      <meshStandardMaterial color={"DarkGrey"} />
      <Edges scale={1} renderOrder={1000}>
        <meshBasicMaterial transparent color="Grey" widthTest={true} />
      </Edges>
    </mesh>
  );
}

export function FanFrame({ width, height, depth, position = [0, 0, 0], rotation = [0, 0, 0] }) {
  var extrudeSettings = {
    depth: depth,
    steps: 1,
    bevelEnabled: false,
    curveSegments: 16,
  };

  const frameWidth = fromMM(30);

  const geom = useMemo(() => {
    return buildDuctPlateWithHole(height, width, false, width - frameWidth * 2, height - frameWidth * 2, 0, frameWidth, fromMM(2));
  }, [height, width]);

  const lip_geometry = useMemo(() => {
    return buildDuct(height, width, false, width, height, 0, 0, fromMM(2));
  }, [height, width]);

  return (
    <group position={position} rotation={rotation}>
      <mesh geometry={geom}>
        <meshStandardMaterial color={"DarkGrey"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="Grey" widthTest={true} />
        </Edges>
      </mesh>
      <mesh>
        <extrudeGeometry args={[lip_geometry, extrudeSettings]} />
        <meshStandardMaterial color={"DarkGrey"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="Grey" widthTest={true} />
        </Edges>
      </mesh>
    </group>
  );
}

useGLTF.preload("/fan.glb");
