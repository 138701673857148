/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import {
  BuildingOfficeIcon,
  CreditCardIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";

// const tabs = [
//   { name: 'My Account', href: '#', icon: UserIcon, current: false },
//   { name: 'Company', href: '#', icon: BuildingOfficeIcon, current: false },
//   { name: 'Team Members', href: '#', icon: UsersIcon, current: true },
//   { name: 'Billing', href: '#', icon: CreditCardIcon, current: false },
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs({ tabs, onClickHandler }) {
  return (
    <div className="4">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:ring-primary-200"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200 px-3">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                // href={tab.href}
                className={classNames(
                  tab.current
                    ? "text-primary"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "group inline-flex items-center py-4 text-sm font-medium cursor-pointer"
                )}
                aria-current={tab.current ? "page" : undefined}
                onClick={ () => onClickHandler (tab.id)}
              >
                {tab.icon && (
                  <tab.icon
                    className={classNames(
                      tab.current
                        ? "text-primary"
                        : "text-gray-400 group-hover:text-gray-500",
                      "-ml-0.5 mr-2 h-5 w-5"
                    )}
                    aria-hidden="true"
                  />
                )}
                <span>{tab.name}</span>
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
