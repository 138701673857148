import { useEffect, useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import axios from "../axios";
import { useStore } from "../components/designer/Store/zustandStore";
import { useNavigate, useLocation } from "react-router-dom";
import {
  selectionQueryKeys,
  configuratorQueryKeys,
  designerQueryKeys,
  printQueryKeys,
  technicalQueryKeys,
  chartQueryKeys,
  userQueryKeys,
  projectQueryKeys,
  pricingQueryKeys,
  adminQueryKeys,
} from "../react-query/queryConstants";
import { HomeIcon, Cog6ToothIcon, PencilSquareIcon, BriefcaseIcon, BuildingLibraryIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { userStore, projectStore, selectionStore } from "../stores/userStore";
import { groupBy, mapValues, omit, isEmpty } from "lodash";

const selectionInformationObject = {
  Volume: 0,
  Pressure: 0,
  ExternalStaticPressure: 0,
  Canopy: "",
  FrameType: "",
  FrameWidth: 0,
  BaseHeight: 0,
  SideOfAccess: 0,
  FanStyle: "",
  FanOptions: "",
  OutletOptions: "",
  ModelId: "DEV-K",
  SelectedVentilationUnitId: "DEV-K-01",
};

export function useModal() {
  const [modal, updateModal] = useState({
    title: "",
    content: "",
  });

  const [openModal, updateOpenModal] = useState(false);

  // useEffect(() => {
  //   updateOpenModal((prevOpenModal) => prevOpenModal)
  // },[])

  // const updateModal = (modal) => {
  // queryClient.setQueryData(modalQueryKeys.modal, modal);
  // };

  const showModal = (m) => {
    updateModal(m);
    updateOpenModal(true);
    //queryClient.invalidateQueries(["updateOpenModal"]);
  };

  // const updateOpenModal = () => {
  //   updateopenModal(true);
  //   return true;
  // }

  // const { data: isModalVisible = false} = useQuery(["updateOpenModal"],updateOpenModal,    {
  //   refetchOnMount: false,
  // });

  return { openModal, updateOpenModal, showModal };
}

export function useConfigurator(instanceIds) {
  const queryClient = useQueryClient();

  const { calculateUnit } = useAHUDesignerManager();

  // const{ventilationInformation} = useConfigurationFan()

  // const configurationModels = [
  //   {
  //     configurationModelId: "DEV-K",
  //     configurationModelDescription: "",
  //     configurationModelIconPath: "",
  //   },
  // ];

  const getSelectionInformation = () => {
    return queryClient.getQueryData(selectionQueryKeys.selectionInformation) ?? selectionInformationObject;
  };

  const fallback = [];

  const { data: selectedUnitModelName = "" } = useQuery(
    [configuratorQueryKeys.GetSelectedUnitModelName],
    async () => {
      //  if (instanceIds || useStore.getState().selectedComponentInstance) {
      return await axios.get(`Configuration/GetSelectedUnitModelName`);
    },
    {
      select: (data) => data?.data,
      refetchOnMount: false,
      // enabled: false,
      //cacheTime:0
    }
  );
  const { data: referrer = "" } = useQuery(
    [configuratorQueryKeys.getReferrer],
    async () => {
      let instanceIds = ["BOM"];
      //  if (instanceIds || useStore.getState().selectedComponentInstance) {
      return await axios.get(`Configuration/GetReferrer`);
    },
    {
      select: (data) => data?.data,
      refetchOnMount: false,
    }
  );

  const { data: configurationInstances = fallback } = useQuery(
    [configuratorQueryKeys.getConfigurationInstances, selectedUnitModelName, useStore.getState().selectedComponentInstance?.join(",")],
    async () => {
      //  if (instanceIds || useStore.getState().selectedComponentInstance) {
      return await axios.get(
        `Configuration/GetInstances/${getSelectionInformation().ModelId}/${useStore.getState().selectedComponentInstance?.join(",")}`
        // `Configuration/GetInstances`,
        // {
        //   params: {
        //     ModelId: getSelectionInformation().ModelId,
        //     configurationInstanceIds: useStore.getState().selectedComponentIds.join(","),
        //   },
        // }
      );
      // } else {
      //   return null;
      // }
    },
    {
      select: (data) => data?.data,
      refetchOnMount: false,
      enabled: !isEmpty(useStore.getState().selectedComponentInstance?.join(",")),
      // cacheTime: 0,
      // retry: false
    }
  );

  const { data: selectedConfigurationInstances = fallback } = useQuery(
    [configuratorQueryKeys.getConfigurationInstances, selectedUnitModelName, "Selected", instanceIds],
    async () => {
      //  if (instanceIds || useStore.getState().selectedComponentInstance) {
      return await axios.get(
        `Configuration/GetInstances/${getSelectionInformation().ModelId}/${instanceIds}`
        // `Configuration/GetInstances`,
        // {
        //   params: {
        //     ModelId: getSelectionInformation().ModelId,
        //     configurationInstanceIds: useStore.getState().selectedComponentIds.join(","),
        //   },
        // }
      );
      // } else {
      //   return null;
      // }
    },
    {
      select: (data) => data?.data,
      //refetchOnMount: false,
      enabled: !isEmpty(instanceIds),
     // cacheTime: 0,

    }
  );

  const { data: BOMConfigurationInstances = fallback } = useQuery(
    [configuratorQueryKeys.getConfigurationInstances, "DEV-K", "BOM"],
    async () => {
      let instanceIds = ["BOM"];
      //  if (instanceIds || useStore.getState().selectedComponentInstance) {
      return await axios.get(`Configuration/GetInstances/${getSelectionInformation().ModelId}/${instanceIds}`);
    },
    {
      select: (data) => data?.data,
     // refetchOnMount: false,
      //enabled: false
    }
  );

  const { mutate: selectVentilationUnit } = useMutation(
    [configuratorQueryKeys.selectVentilationUnit],
    async ({ ventilationUnitId, referrer }) => {
      return await axios.post(`Configuration/SelectVentilationUnit/${ventilationUnitId}/${referrer}`, {});
    },
    {
      onSuccess: (result) => {
        calculateUnit();
      },
    }
  );

  return {
    configurationInstances,
    selectedConfigurationInstances,
    BOMConfigurationInstances,
    selectedUnitModelName,
    selectVentilationUnit,
    referrer,
  };
}

export function useConfigurationFan(selectedUUID) {
  const queryClient = useQueryClient();
  const { calculateUnit } = useAHUDesignerManager();

  const { data: fans = null } = useQuery(
    [configuratorQueryKeys.getSelectedUnitFans, selectedUUID],
    async () => {
      if (selectedUUID) {
        console.log("fanitems is not null");
        return await axios.get(`Configuration/GetSelectedUnitFans/${selectedUUID[0]}`);
      } else {
        console.log("fan item is null");
        return null;
      }
    },
    {
      select: (data) => data?.data,
      refetchOnMount: false,
      cacheTime: 0,
    }
  );

  const { mutate: selectFan } = useMutation(
    async (data) =>
      await axios.post(
        "/Configuration/SelectFan",
        {},
        {
          params: {
            ventilationUnitId: data.ventilationUnitId,
            fanId: data.fanModelName,
            referrer: "Selector",
          },
        }
      ),
    {
      onSuccess: (result) => {
        calculateUnit();
        queryClient.invalidateQueries([configuratorQueryKeys.getSelectedUnitFans]);
      },
      onError:(result) =>
      {

      }
    }
  );

  const { mutate: selectDrive } = useMutation(
    async (data) =>
      await axios.post(
        "/Configuration/SelectDrive",
        {},
        {
          params: {
            ventilationUnitId: data.ventilationUnitId,
            fanId: data.fanModelName,
            driveId:data.driveId,
            referrer: "Selector",
          },
        }
      ),
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries([configuratorQueryKeys.getSelectedUnitFans, selectedUUID],);

        queryClient.invalidateQueries([technicalQueryKeys.getTechnicalSections]);
        queryClient.invalidateQueries([pricingQueryKeys.getPricingList]);  
      },
    }
  );

  return {
    fans,
    selectFan,
    selectDrive
  };
}

export function useConfigurationInstance(configurationInstanceId = "") {
  const fallback = null;

  const { data: configurationInstance = fallback } = useQuery(
    [configuratorQueryKeys.GetConfigurationInstance, configurationInstanceId, ],
    async () => {
      return await axios.get(`Configuration/GetConfigurationInstance/${configurationInstanceId}`);
    },
    {
      select: (data) => data.data,
      //refetchOnMount: false,
      enabled: !isEmpty(configurationInstanceId),
      //cacheTime:0
    }
  );

  return {
    configurationInstance,
  };
}

export function useConfigurationInstanceBOMId(configurationInstanceId = "") {
  const { data: configurationItemBOMId = "" } = useQuery(
    [configuratorQueryKeys.GetConfigurationInstanceBOMId, configurationInstanceId],
    async () => {
      return await axios.get(`Configuration/GetConfigurationInstanceBOMId/${configurationInstanceId}`);
    },
    {
      select: (data) => data.data,
      //refetchOnMount: false,
    }
  );

  return { configurationItemBOMId };
}

export function useUpdateConfiguration(cv, ci) {
  const queryClient = useQueryClient();
  const { calculateUnit } = useAHUDesignerManager();

  const { mutate } = useMutation(async (cv, ci) => await axios.post("/Configuration/UpdateAvailability", cv), {
    onSuccess: (result) => {
      //const configuratorInstance = result.data;
      calculateUnit();
      // queryClient.invalidateQueries([configuratorQueryKeys.GetConfigurationInstance, configuratorInstance.configurationInstanceId]);
      // queryClient.invalidateQueries([configuratorQueryKeys.GetConfigurationInstance]);

    },
  });

  return mutate;
}

export function useSelection() {
  const queryClient = useQueryClient();
  const { calculateUnit } = useAHUDesignerManager();

  // const { calculateUnit } = useAHUDesignerManager();

  const { data: ventilationInformation = null } = useQuery(
    [selectionQueryKeys.getVentilationInformation],
    async () => {
      return await axios.get(`Configuration/GetVentilationInformation`);
    },
    {
      select: (data) => data.data,
    }
  );

  //const [selectionStore, setSelectionStore] = useState(queryClient.getQueryData([selectionQueryKeys.selectionStore]) ?? selectionInformationObject);

  const [velocityPressure, setVelocityPressure] = useState(0);
  const fallback = [];

  let enableQuery = false;

  const selectProducts = (selectionInformation) => {
    updateSelectionInformation(selectionInformation);
    enableQuery = true;
    refetch();
  };

  const updateSelectionInformation = (selectionInformation) => {
    queryClient.setQueryData(selectionQueryKeys.selectionInformation, selectionInformation);
    //refetch();
  };

  const getSelectionInformation = () => {
    return queryClient.getQueryData(selectionQueryKeys.selectionInformation) ?? selectionInformationObject;
  };

  const { data: selectionResults = fallback, refetch } = useQuery(
    [selectionQueryKeys.selectProducts, getSelectionInformation().Volume, getSelectionInformation().Pressure],
    async () => {
      return await axios.post(
        "/selection/SelectProducts",
        {},
        {
          params: {
            velocityPressure: getSelectionInformation().Volume,
            pressure: getSelectionInformation().Pressure,
          },
        } // for now
      );
    },
    {
      select: (data) => data.data,
      refetchOnMount: false,
      //enabled: false, // disable the query from automatically running
    }
  );

  const { mutate: getUnits } = useMutation(async (vInfo) => await axios.post("/Configuration/GetUnits", vInfo), {
    onSuccess: (result) => {
      calculateUnit();
      // queryClient.invalidateQueries([configuratorQueryKeys.GetConfigurationInstance]);
      // queryClient.invalidateQueries([designerQueryKeys.getAHUDesignerManaager]);
      // queryClient.invalidateQueries([configuratorQueryKeys.GetSelectedUnitModelName]);
      // queryClient.invalidateQueries([technicalQueryKeys.getTechnicalSections]);
      // queryClient.invalidateQueries([pricingQueryKeys.getPricingList]);
      // queryClient.invalidateQueries([chartQueryKeys.getChartModel]);
    },
  });

  const { mutate: resetSelection } = useMutation(
    [configuratorQueryKeys.resetSelection],
    async () => {
      return await axios.post("Configuration/ResetSelection", {});
    },
    {
      onSuccess: (result) => {
        calculateUnit();
        // getUnits(ventilationInformation);
        // queryClient.invalidateQueries([configuratorQueryKeys.getReferrer]);
        // queryClient.invalidateQueries([configuratorQueryKeys.GetSelectedUnitModelName]);
        // queryClient.invalidateQueries([configuratorQueryKeys.GetConfigurationInstance, "DesignDetails"]);
      },
    }
  );

  return {
    velocityPressure,
    // selectProducts,
    selectionResults,
    //selectionFields,
    getSelectionInformation,
    selectionInformationObject,
    updateSelectionInformation,
    ventilationInformation,
    getUnits,
    resetSelection,
  };
}

export function useAHUDesignerManager() {
  const [selectedUUID, setSelectedUUID] = useStore((state) => [state.selectedUUID, state.setSelectedUUID]);

  //const {selectedUnitModelName} = useConfigurator();

  const fallback = null;

  const queryClient = useQueryClient();

  //const { getSelectionInformation } = useSelection();

  //const ventilationUnitId = getSelectionInformation().SelectedVentilationUnitId;

  const { data: ahuDesignerManager = fallback, refetch } = useQuery(
    [designerQueryKeys.getAHUDesignerManaager],
    async () => {
      // if (ventilationUnitId) {
      return await axios.get(
        //    `AHUDesigner/GetAHUDesignerManager/${ventilationUnitId}`,
        //   // `AHUDesigner/GetAHUDesignerManager`,
        //   {
        //     params: { ventilationUnitId: ventilationUnitId },
        //   }
        // );
        // // } else {
        // //   return null;
        // // }
        `AHUDesigner/GetAHUDesignerManager`
      );
    },
    {
      select: (data) => data.data,
      //enabled:false,//;// !isEmpty(selectedUnitModelName),
      refetchOnMount: false,

      //enabled: false, // disable the query from automatically running
    }
  );

  const { mutate: addComponent } = useMutation(
    async (data) =>
      await axios.post(
        "/AHUDesigner/AddComponent",
        // {
        //   attachToComponentId: id,
        //   configurationItemId: ci,
        //   side:side
        // }
        null,
        {
          params: {
            attachToComponentId: data.id,
            configurationItemId: data.ci,
            side: data.side,
          },
        }
      ),
    {
      onSuccess: (result) => {
        const configuratorInstance = result.data;
        queryClient.invalidateQueries([configuratorQueryKeys.getConfigurationInstances, "DEV-K", "BOM"]);
        calculateUnit();

        // queryClient.invalidateQueries([
        //   configuratorQueryKeys.GetConfigurationInstance,
        // ]);
        //queryClient.invalidateQueries([designerQueryKeys.getAHUDesignerManaager]);
      },
    }
  );

  const { mutate: calculateUnit } = useMutation(async () => await axios.post("/Configuration/CalculateUnit"), {
    onSuccess: (result) => {
      
      const configuratorInstance = result.data;
      queryClient.invalidateQueries([designerQueryKeys.getAHUDesignerManaager]);
      queryClient.invalidateQueries([technicalQueryKeys.getTechnicalSections]);
      queryClient.invalidateQueries([chartQueryKeys.getChartModel]);
      queryClient.invalidateQueries([pricingQueryKeys.getPricingList]);
      queryClient.invalidateQueries([configuratorQueryKeys.getReferrer]);
      queryClient.invalidateQueries([configuratorQueryKeys.GetSelectedUnitModelName]);

      queryClient.invalidateQueries([configuratorQueryKeys.GetConfigurationInstance, configuratorInstance.configurationInstanceId]);
      queryClient.invalidateQueries([configuratorQueryKeys.GetConfigurationInstance]);

    },
  });

  const { mutate: deleteComponent } = useMutation(
    async () =>
      await axios.post("/AHUDesigner/DeleteComponent", null, {
        params: { stringComponentIds: JSON.stringify(selectedUUID) },
      }),
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries([configuratorQueryKeys.getConfigurationInstances, "DEV-K", "BOM"]);
        calculateUnit();
      },
    }
  );

  const { mutate: makeSection } = useMutation(
    async (data) =>
      await axios.post("/AHUDesigner/MakeSection", null, {
        params: { stringComponentIds: JSON.stringify(data) },
      }),
    {
      onSuccess: (result) => {
        setSelectedUUID([]);
        calculateUnit();
        // queryClient.invalidateQueries([designerQueryKeys.getAHUDesignerManaager]);
        // queryClient.invalidateQueries([technicalQueryKeys.getTechnicalSections]);
        // queryClient.invalidateQueries([chartQueryKeys.getChartModel]);
      },
    }
  );

  const { mutate: removeSection } = useMutation(
    async () =>
      await axios.post("/AHUDesigner/RemoveSection", null, {
        params: { stringComponentIds: JSON.stringify(selectedUUID) },
      }),
    {
      onSuccess: (result) => {
        setSelectedUUID([]);
        calculateUnit();
        // queryClient.invalidateQueries([designerQueryKeys.getAHUDesignerManaager]);
        // queryClient.invalidateQueries([technicalQueryKeys.getTechnicalSections]);
        // queryClient.invalidateQueries([chartQueryKeys.getChartModel]);
      },
    }
  );

  return {
    ahuDesignerManager,
    addComponent,
    calculateUnit,
    deleteComponent,
    makeSection,
    removeSection,
  };
}

export function useNav() {
  const location = useLocation();

  const [navigations, setNavigations] = useState([
    {
      name: "Selection",
      href: "/selection",
      icon: HomeIcon,
      current: location.pathname === "/selection",
    },
    {
      name: "Project",
      href: "/project",
      icon: BriefcaseIcon,
      current: location.pathname === "/project",
    },
    {
      name: "Admin",
      href: "/admin/users",
      icon: BuildingLibraryIcon,
      current: location.pathname === "/admin/users",
      adminOnly: true,
    },
  ]);

  const updateCurrentNavigation = (key) => {
    location.pathname = `/${key}`;
    const updatedNavigations = [...navigations];
    for (let navigation of updatedNavigations) {
      navigation.current = navigation.name === key;
    }
    setNavigations(updatedNavigations);
  };

  return { navigations, updateCurrentNavigation };
}

export function usePrint(printRequest) {
  const { data } = useQuery(
    [printQueryKeys.print],
    async (printRequest) => {
      return await axios.post(
        "/Print/Print",
        {},
        {
          params: {
            printRequest: printRequest,
          },
        }
      );
    },
    {
      select: (data) => data.data,
      refetchOnMount: false,
      //enabled: false, // disable the query from automatically running
    }
  );
}

export function useTechnical() {
  const { selectedUnitModelName } = useConfigurator();

  const { data: technicalSections = null } = useQuery(
    [technicalQueryKeys.getTechnicalSections],
    async () => {
      return await axios.get(`Technical/GetTechnicalSections`);
    },
    {
      select: (data) => data?.data,
      refetchOnMount: false,
      //cacheTime: 0,
      enabled: !isEmpty(selectedUnitModelName),
    }
  );

  function convertTechnicalSectionItems(technicalSection) {
    let rowItems = [];
    mapValues(groupBy(technicalSection.items, "rowId"), (clist) => {
      clist.map((c) => omit(c, "rowId"));
      rowItems.push(clist);
    });
    return rowItems;
  }

  return {
    technicalSections,
    convertTechnicalSectionItems,
  };
}

export function useChart() {
  const { data: chartModel = null } = useQuery(
    [chartQueryKeys.getChartModel],
    async () => {
      return await axios.get(`Technical/GetChartModel`);
    },
    {
      select: (data) => data?.data,
      refetchOnMount: false,
      //cacheTime: 0,
    }
  );

  return {
    chartModel,
  };
}

export function useUser() {
  const [user, setUser, isAuthenticated, setIsAuthenticated] = userStore((state) => [state.user, state.setUser, state.isAuthenticated, state.setIsAuthenticated]);
  const { setProject } = useProject();
  const navigate = useNavigate();

  const [refreshTokenTimeout, setRefreshTokenTimeout] = useState();

  const prevLocation = useLocation();

  const queryClient = useQueryClient();

  useEffect(() => {
    setIsAuthenticated(localStorage.getItem("user") != null);
  }, [user]);

  const refreshToken = () => {
    axios
      .post("/User/refresh-token")
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data ?? ""));
        setUser(response.data);
        startRefreshTokenTimer(response.data);
      })
      .catch(() => {
        logout();
      });
  };

  const startRefreshTokenTimer = (user) => {
    // parse json object from base64 encoded jwt token

    const jwtBase64 = user.jwtToken.split(".")[1];
    const jwtToken = JSON.parse(atob(jwtBase64));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    setRefreshTokenTimeout(setTimeout(refreshToken, timeout));
  };

  const stopRefreshTokenTimer = () => {
    clearTimeout(refreshTokenTimeout);
  };

  const { mutate: login } = useMutation(
    async (loginDetails) =>
      await axios.post("/User/authenticate", loginDetails).then((result) => {
        if (result) {
          const user = result.data;
          // update pinia state

          // store user details and jwt in local storage to keep user logged in between page refreshes
          localStorage.setItem("user", JSON.stringify(user ?? ""));

          setUser(user);

          //queryClient.setQueryData(userQueryKeys.user, user);

          // redirect to previous url or default to home page
          navigate("/selection");
          // router.push(returnUrl.value || "/");
        }
      }),
    {
      onSuccess: (result) => {},
    }
  );

  const { mutate: registerUser } = useMutation(async (registerDetails) => await axios.post("/User/register", registerDetails), {
    onSuccess: (result) => {
      // update pinia state

      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user ?? ""));
      setUser(result.data);

      // redirect to previous url or default to home page
      // router.push(returnUrl.value || "/");
      navigate("/login");

      toast("Thank your for registering. Please check your email to verify.");
    },
  });

  const { mutate: verifyEmail } = useMutation(
    async (token) =>
      await axios.post("/User/verify-email", {
        token: token,
      }),
    {
      onSuccess: (result) => {
        setUser(result);
        // update state
        startRefreshTokenTimer(user);

        // store user details and jwt in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user ?? ""));

        // redirect to previous url or default to home page
        // router.push(returnUrl.value || "/");
        navigate("/login");

        toast("Email or password is incorrect");
      },
    }
  );

  // const logout = () => {
  //   localStorage.removeItem("user");
  //   queryClient.setQueryData(userQueryKeys.user, null);
  //   setUser(null);
  //   stopRefreshTokenTimer();
  //   navigate("/login");
  // };

  const { mutate: updateUser } = useMutation(async (updateRequest) => await axios.post("/User/update-user", updateRequest), {
    onSuccess: (result) => {
      user = result.data;
      // update pinia state

      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user ?? ""));

      // redirect to previous url or default to home page
      // router.push(returnUrl.value || "/");
      navigate("/login");

      toast("Account updated successfully.");
    },
  });

  const { mutate: logout } = useMutation(async () => await axios.post("/User/logout"), {
    onSuccess: () => {
      localStorage.removeItem("user");
      setProject(null);
      queryClient.setQueryData(userQueryKeys.user, null);
      setUser(null);
      stopRefreshTokenTimer();
      queryClient.clear();
      navigate("/login");
    },
  });

  return { login, registerUser, verifyEmail, user, isAuthenticated, logout, updateUser, refreshToken };
}

export function useProject(locationCode) {
  const queryClient = useQueryClient();
  const [project, setProject] = projectStore((state) => [state.project, state.setProject]);
  const [user] = userStore((state) => [state.user]);

  const fallback = [];

  const { data: projectLocations = fallback } = useQuery(
    [projectQueryKeys.getProjectLocations],
    async () => {
      return await axios.get(`Project/get-project-locations/GULF`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
      //refetchOnMount: false,
      // cacheTime: 0,
      // retry: false
    }
  );

  const { data: cities = fallback } = useQuery(
    [projectQueryKeys.getProjectCities, locationCode],
    async () => {
      return await axios.get(`Project/get-project-cities/${locationCode}`);
    },
    {
      select: (data) => data?.data,
      enabled: locationCode != null,
      //refetchOnMount: false,
      // cacheTime: 0,
      // retry: false
    }
  );

  const { data: projectStatus = fallback } = useQuery(
    [projectQueryKeys.getProjectStatus],
    async () => {
      return await axios.get(`Project/get-project-status`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
    }
  );

  const { data: poStatus = fallback } = useQuery(
    [projectQueryKeys.getPOStatus],
    async () => {
      return await axios.get(`Project/get-po-status`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
    }
  );

  const { data: lostReasons = fallback } = useQuery(
    [projectQueryKeys.getReasonForLost],
    async () => {
      return await axios.get(`Project/get-lost-reasons`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
    }
  );

  const { data: competitors = fallback } = useQuery(
    [projectQueryKeys.getCompetitor],
    async () => {
      return await axios.get(`Project/get-competitor`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
    }
  );

  const { data: consultants = fallback } = useQuery(
    [projectQueryKeys.getConsultant],
    async () => {
      return await axios.get(`Project/get-consultant`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
    }
  );

  const { data: serviceTypes = fallback } = useQuery(
    [projectQueryKeys.getServiceType],
    async () => {
      return await axios.get(`Project/get-service-type`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
    }
  );

  const { data: currenProject, refetch: getCurrentProject } = useQuery(
    [projectQueryKeys.getCurrentProject],
    async () => {
      return await axios.get(`Project/get-current-project`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
      //refetchOnMount: false,
      // cacheTime: 0,
      // retry: false
    }
  );

  const { data: projectLists = fallback } = useQuery(
    [projectQueryKeys.getProjectLists],
    async () => {
      return await axios.get(`Project/get-projects`);
    },
    {
      select: (data) => data?.data,
      enabled: user != null,
      //refetchOnMount: false,
      // cacheTime: 0,
      // retry: false
    }
  );

  useEffect(() => {
    setProject(currenProject);
  }, [currenProject]);

  const { mutate: addToProject } = useMutation(async (addItemRequest) => await axios.post("/Project/add-item", addItemRequest), {
    onSuccess: (result) => {
      getCurrentProject();
      //queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);
      getProjectItems();
      toast("Unit successfully added to project");
    },
  });

  const { data: projectItems, refetch: getProjectItems } = useQuery(
    [projectQueryKeys.getProjectItems],
    async () => {
      return await axios.get("/Project/get-project-items");
    },
    {
      select: (data) => data?.data,
      //refetchOnMount: false,
      // cacheTime: 0,
      // retry: false
    }
  );

  const { mutate: saveProject } = useMutation(async (saveProjectRequest) => await axios.post("/Project/save", saveProjectRequest), {
    onSuccess: (result) => {
      queryClient.invalidateQueries([projectQueryKeys.getCurrentProject]);
      queryClient.invalidateQueries([projectQueryKeys.getProjectLists]);
      toast("Project successfully saved");
    },
  });

  const { mutate: openProject } = useMutation(async (saveProjectRequest) => await axios.post("/Project/open-project", saveProjectRequest), {
    onSuccess: (result) => {
      queryClient.invalidateQueries([projectQueryKeys.getCurrentProject]);
      queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);

      // projectLists = result;
      // toast("Project successfully saved");
    },
  });

  const { mutate: newProject } = useMutation(async () => await axios.get("/Project/new"), {
    onSuccess: (result) => {
      queryClient.invalidateQueries([projectQueryKeys.getCurrentProject]);
      queryClient.setQueryData([projectQueryKeys.getProjectItems], result?.data?.value);
      toast("New project created");
    },
  });

  const { mutate: updateProjectItem } = useMutation(async () => await axios.post("/Project/update-project-item"), {
    onSuccess: (result) => {
      queryClient.invalidateQueries([projectQueryKeys.getCurrentProject]);
      queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);
      //queryClient.setQueryData([projectQueryKeys.getProjectItems], result?.data?.value);
      toast("Project item successfully updated");
    },
  });
  const { mutate: deleteProjectItem } = useMutation(async (deleteProjectItemRequest) => await axios.post("/Project/remove-item", deleteProjectItemRequest), {
    onSuccess: (result) => {
      queryClient.invalidateQueries([projectQueryKeys.getCurrentProject]);
      queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);

      // queryClient.setQueryData([projectQueryKeys.getProjectItems], result?.data?.value);
      toast("Project item successfully removed");
    },
  });

  const { mutate: deleteProject } = useMutation(async (deleteProjectRequest) => await axios.post("/Project/delete", deleteProjectRequest), {
    onSuccess: (result) => {
      queryClient.invalidateQueries([projectQueryKeys.getProjectLists]);
      // queryClient.setQueryData([projectQueryKeys.getProjectItems], result?.data?.value);
      toast("Project successfully deleted");
    },
  });
  const { mutate: cloneProjectItem } = useMutation(async (cloneProjectItemRequest) => await axios.post("/Project/clone-project-item", cloneProjectItemRequest), {
    onSuccess: (result) => {
      queryClient.invalidateQueries([projectQueryKeys.getProjectItems]);
      // queryClient.setQueryData([projectQueryKeys.getProjectItems], result?.data?.value);
      toast("Item successfully copied");
    },
  });

  // const { data: projectLists = fallback } = useQuery(
  //   [configuratorQueryKeys.getProjectLists],
  //   async () => {
  //     return await axios.get("/Project/get-projects");
  //   },
  //   {
  //     select: (data) => data?.data,
  //     //refetchOnMount: false,
  //     // cacheTime: 0,
  //     // retry: false
  //   }
  // );

  return {
    project,
    setProject,
    addToProject,
    projectItems,
    saveProject,
    projectLists,
    openProject,
    newProject,
    deleteProject,
    updateProjectItem,
    projectLocations,
    cities,
    projectStatus,
    poStatus,
    lostReasons,
    competitors,
    consultants,
    serviceTypes,
    deleteProjectItem,
    cloneProjectItem
  };
}

export function usePricing(ventilationUnitId) {
  const { data: pricingList } = useQuery(
    [pricingQueryKeys.getPricingList, ventilationUnitId],
    async () => {
      return await axios.get(`Configuration/get-pricing-list/${ventilationUnitId}/Selector`);
    },
    {
      select: (data) => data?.data,
      enabled: ventilationUnitId != null && ventilationUnitId != "" && ventilationUnitId != undefined,
    }
  );

  return { pricingList };
}

export function useAdmin() {
  const queryClient = useQueryClient();

  const [user] = userStore((state) => [state.user]);

  const { data: userList } = useQuery(
    [adminQueryKeys.getUserList],
    async () => {
      return await axios.get("User/get-all-user");
    },
    {
      select: (result) => result.data,
      enabled: user?.userGroupId == 2,
    }
  );

  const { mutate: approveUser } = useMutation(async (approveRequest) => await axios.post("/User/approve-user", approveRequest), {
    onSuccess: () => {
      queryClient.invalidateQueries([adminQueryKeys.getUserList]);

      toast("User successfully approved.");

      // localStorage.removeItem("user");
      // queryClient.setQueryData(userQueryKeys.user, null);
      // setUser(null);
      // stopRefreshTokenTimer();
      // navigate("/login");
    },
  });
  const { mutate: revokeUser } = useMutation(async (revokeRequest) => await axios.post("/User/revoke-user", revokeRequest), {
    onSuccess: () => {
      queryClient.invalidateQueries([adminQueryKeys.getUserList]);

      toast("User successfully revoked.");

      // localStorage.removeItem("user");
      // queryClient.setQueryData(userQueryKeys.user, null);
      // setUser(null);
      // stopRefreshTokenTimer();
      // navigate("/login");
    },
  });
  const { mutate: deleteUser } = useMutation(async (deleteRequest) => await axios.post("/User/delete-user", deleteRequest), {
    onSuccess: () => {
      queryClient.invalidateQueries([adminQueryKeys.getUserList]);

      toast("User successfully removed.");

      // localStorage.removeItem("user");
      // queryClient.setQueryData(userQueryKeys.user, null);
      // setUser(null);
      // stopRefreshTokenTimer();
      // navigate("/login");
    },
  });

  return { userList, approveUser, revokeUser, deleteUser };
}
