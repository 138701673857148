import { useEffect } from "react";
import fileDownload from "js-file-download";
import Button from "../shared/Button";
import axios from "../../axios";
import Loading from "../layout/Loading";
import { useState } from "react";
import { useStore } from "../designer/Store/zustandStore";
import { useProject } from "../../hooks/useQueries";
import { isEmpty } from "lodash";
import { selectionStore } from "../../stores/userStore";
import { useConfigurator } from "../../hooks/useQueries";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";

export default function SelectionHeader({ ventilationUnitId, toggleConfigMenu }) {
  const { addToProject, updateProjectItem } = useProject();
  const [printing, setPrinting] = useState(false);
  const [project, setProject, drawings, setRequestDrawings] = useStore((state) => [state.project, state.setProject, state.drawings, state.setRequestDrawings]);
  const { referrer } = useConfigurator();
  const print = (printType) => {
    setRequestDrawings(true);
    setPrinting(true);
  };

  useEffect(() => {
    if (printing) {
      const printRequest = {
        Id: ventilationUnitId,
        PrintType: 0,
        Referrer: "Selector",
        Culture: "en-GB",
        Drawings: useStore.getState().drawings,
      };
      axios
        .post(`/Print/Print`, printRequest, {
          responseType: "blob",
        })
        .then((response) => {
          fileDownload(response.data, ventilationUnitId + ".pdf");
          setPrinting(false);
        })
        .catch(function (error) {
          setPrinting(false);
        });
    }
  }, [drawings]);

  return (
    <div className="relative flex py-2 gap-1.5 ml-3 text-sm">
      <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md" onClick={print} disabled={isEmpty(ventilationUnitId)}>
        Print
      </Button>
      {referrer != "Project" && (
        <Button
          text="Add to Project"
          buttoncolor="primary"
          buttonSize="xs"
          onClick={() => addToProject({ ResultId: ventilationUnitId })}
          disabled={isEmpty(ventilationUnitId)}
        ></Button>
      )}
      {referrer == "Project" && (
        <Button text="Update Project Item" buttoncolor="danger" buttonSize="xs" onClick={() => updateProjectItem()} disabled={isEmpty(ventilationUnitId)}></Button>
      )}

      <Loading cssClass="justify-end float-right ml-auto w-5 mr-3" isCalculating={printing} />
    </div>
  );
}
